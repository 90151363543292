<template>
	<div>
        <!-- 领取按钮 -->
        <!-- <div class="gradient-left-red-purple-137-dark">
            <v-btn rounded="0" elevation="2" color="primary" :loading="loading" class="text-none text-white body-p-small-b" height="33" width="137" aria-label="Test Tokens" @click="dialog = true">Test Tokens</v-btn>
        </div> -->
        <!-- 新的领取按钮 -->
        <div style="opacity: .9; filter: blur(0px); transform: translateY(0%); will-change: transform;">
            <a :loading="loading" type="button" class="button_root button_variant-ghost button_size-default button_variant link_root" rel="noopener" @click="dialog = true">
                <span class="gjcrSl" style="padding: 0px;">Test Tokens</span><v-icon>mdi mdi-chevron-right</v-icon>
            </a>
        </div>
        <!-- 弹窗组件 -->
        <v-dialog v-model="dialog" content-class="v-echarts-dialog" :persistent="loading || claimed" width="500">
            <v-card class="pa-8" rounded="0" elevation="12" color="white01">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card-title class="d-flex px-0">
                            <div class="sidebar-h2 text-primaryGrey">
                                <span v-if="!claimed">Test Tokens</span>
                                <span v-if="claimed">Minted!</span>
                            </div>
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey" @click="closeDialog"></v-icon>
                        </v-card-title>
                        <v-divider class="my-4"></v-divider>
                        <!-- --------------------  领取 ---------------------- -->
                        <div v-if="!claimed">
                            <!-- 钱包 -->
                            <div v-if="user?.wallet != null">
                                <div class="body-h4 text-primaryGrey mt-8 mb-3">Account/Wallet</div>
                                <v-text-field v-model="user.wallet" variant="solo" density="comfortable" hide-details readonly></v-text-field>
                            </div>
                            <!-- 区块链 -->
                            <div class="mb-3 mt-8">
                                <h3 class="body-h4 text-primaryGrey">Blockchain<span class="ml-1 text-red">*</span></h3>
                            </div>
                            <v-select v-model="currentBlockchain" :items="blockchainTokens" item-title="blockchain.blockchain" variant="outlined" density="comfortable" return-object hide-details>
                                <template v-slot:selection="{ item }" >
                                    <img class="mr-3" :src="item.raw?.blockchain?.icon" width="24" />
                                    <span>{{ item.title }}</span>
                                </template>
                            </v-select>
                            <!-- 代币 -->
                            <div class="mb-3 mt-8">
                                <h3 class="body-h4 text-primaryGrey">Token<span class="ml-1 text-red">*</span></h3>
                            </div>
                            <v-select v-model="currentToken" :items="currentBlockchain?.tokens" item-title="currency" variant="outlined" density="comfortable" return-object hide-details>
                                <template v-slot:item="{ props, item }">
                                    <v-list-item v-bind="props">
                                        <template v-slot:title>
                                            <span class="mr-2">{{ item.raw?.currency }}</span>
                                            <span class="mr-2">({{ item.raw?.name }})</span>
                                        </template>
                                        <template v-slot:subtitle>
                                            <span class="mr-2 body-p-small" v-if="item?.raw?.address != null">{{ item?.raw?.address }}</span>
                                        </template>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <!-- 当前选中的代币 -->
                            <div class="mt-1">
                                <span v-if="user?.wallet != null" class="body-p text-primaryGrey">Balance: {{ $tools.formatNumber(balance, 6) }}</span>
                                <span class="ml-2">
                                    <v-icon size="16" aria-label="autorenew" icon="mdi:mdi-autorenew text-primaryGrey gradient-text-hover" @click="getBalance"></v-icon>
                                </span>
                            </div>
                            <!-- 操作 -->
                            <div class="text-center mb-5 mt-16">
                                <!-- 连接钱包 -->
                                <router-link v-if="user?.wallet == null" :to="'/connectwallet?redirectUrl=' + currentUrl" class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="Connect Wallet" @click="dialog = false">Connect Wallet</v-btn>
                                </router-link>
                                <!-- 水龙头 -->
                                <a v-else-if="currentToken?.url != null && currentToken?.isNativeToken" :href="currentToken?.url" target="_blank" class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" :disabled="isDisabledButton" :loading="loading" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="Open Faucet">Open Faucet</v-btn>
                                </a>
                                <!-- Uniswap -->
                                <a v-else-if="currentToken?.url != null && currentToken?.isSwap" :href="currentToken?.url" target="_blank" class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" :disabled="isDisabledButton" :loading="loading" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" aria-label="Swap">Swap</v-btn>
                                </a>
                                <!-- 铸币 -->
                                <a v-else class="del-underline gradient-left-red-purple-200">
                                    <v-btn rounded="0" elevation="2" :disabled="isDisabledButton" :loading="loading" color="button01" class="text-none text-grey05 body-p-small-b" width="200" height="52" @click="mint" aria-label="Mint">Mint</v-btn>
                                </a>
                            </div>
                        </div>
                        <!-- --------------------  已领取 ---------------------- -->
                        <div v-if="claimed">
                            <div class="mt-3 body-p text-primaryGrey">
                                Congratulations! {{ currentToken?.currency }}({{ currentToken?.name }}) test tokens have been minted to your wallet.
                            </div>
                            <div class="mt-16 mb-5 gradient-underline-hover">
                                <a :href="$tools.getBlockchainExplorerUrl(currentBlockchain?.blockchain?.blockchain) + '/tx/' + transactionHash" class="del-underline text-primaryGrey gradient-text-hover body-p pointer" target="_blank">
                                    <i class="iconfont icon-export pr-3 text-primaryGrey"></i>View on {{ currentBlockchain?.blockchain?.blockchainExplorer }}
                                </a>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <!-- 钱包组件 -->
        <MetaMask ref="MetaMask" @transactionClose="transactionClose"></MetaMask>
        <WalletConnectV2 ref="WalletConnect" @transactionClose="transactionClose"></WalletConnectV2>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Web3 from "web3";
export default {
    data(){
        return {
            // 当前页面链接
            currentUrl: window.location.href,
            // 弹窗
            dialog: false,
            // 加载中
            loading: false,
            // 交易哈希
            transactionHash: null,
            // 已领取
            claimed: false,
            // 当前的选中的区块链
            currentBlockchain: null,
            // 当前选中的代币
            currentToken: null,
            // 余额
            balance: 0
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['user', 'walletType', 'blockchains']),
        // 区块链的代币
        blockchainTokens() {
            let sepolia = {
                blockchain: JSON.parse(JSON.stringify(this.blockchains.filter(b => b.blockchain == 'Sepolia')[0])),
                tokens: [
                    { currency: 'ETH', name: 'Ether', address: null, tokenDecimals: 18, url: 'https://sepoliafaucet.com', isNativeToken: true, isSwap: false },
                    { currency: 'WBTC', name: 'Wrapped Bitcoin', address: '0xd842c0142d3d4981f44b25f37df737a8a2051279', tokenDecimals: 8, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'WETH', name: 'Wrapped Ethereum', address: '0xe9a66f7c67878cfc79453f4e65b39e98de934d5a', tokenDecimals: 18, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'SOL', name: 'Solana', address: '0x3a364a524bafbba6c5e6790420d12fd1868e34e8', tokenDecimals: 9, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'LINK', name: 'Chainlink', address: '0x2a504f158b090b7a752db56a97af77a11eafcf58', tokenDecimals: 18, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'UNI', name: 'Uniswap', address: '0xdd805df0808ee05aa213e287b711a9c47ee8243c', tokenDecimals: 18, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'UN', name: 'untrading', address: '0xc7394a753ccc56b6d3db5941ceae105fb54fa97e', tokenDecimals: 18, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'USDT', name: 'USDT', address: '0x260be7afe286efe0316c2c6ab29e6a9d95f11f53', tokenDecimals: 6, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'USDC', name: 'USDC', address: '0x87d3240ddd75ad3ade741fc831ed373733267118', tokenDecimals: 6, url: null, isNativeToken: false, isSwap: false },
                ],
            };
            let goerli = {
                blockchain: JSON.parse(JSON.stringify(this.blockchains.filter(b => b.blockchain == 'Goerli')[0])),
                tokens: [
                    { currency: 'ETH', name: 'Ether', address: null, tokenDecimals: 18, url: 'https://goerli-faucet.pk910.de', isNativeToken: true, isSwap: false },
                    { currency: 'WBTC', name: 'Wrapped BTC', address: '0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05', tokenDecimals: 8, url: 'https://app.uniswap.org/swap?chain=goerli&inputCurrency=ETH&outputCurrency=0xC04B0d3107736C32e19F1c62b2aF67BE61d63a05', isNativeToken: false, isSwap: true },
                    { currency: 'WETH', name: 'Wrapped Ether', address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', tokenDecimals: 18, url: 'https://app.uniswap.org/swap?chain=goerli&inputCurrency=ETH&outputCurrency=0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6', isNativeToken: false, isSwap: true },
                    { currency: 'USDC', name: 'USD Coin', address: '0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C', tokenDecimals: 6, url: 'https://app.uniswap.org/swap?chain=goerli&inputCurrency=ETH&outputCurrency=0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C', isNativeToken: false, isSwap: true },
                    { currency: 'USDT', name: 'USDT', address: '0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49', tokenDecimals: 6, url: 'https://app.uniswap.org/swap?chain=goerli&inputCurrency=ETH&outputCurrency=0xC2C527C0CACF457746Bd31B2a698Fe89de2b6d49', isNativeToken: false, isSwap: true },
                ],
            };
            let mumbai = {
                blockchain: JSON.parse(JSON.stringify(this.blockchains.filter(b => b.blockchain == 'Mumbai')[0])),
                tokens: [
                    { currency: 'MATIC', name: 'MATIC', address: null, tokenDecimals: 18, url: 'https://mumbaifaucet.com', isNativeToken: true, isSwap: false },
                    { currency: 'WETH', name: 'Wrapped Ether', address: '0x47ce7e72334fe164954d4f9dd95f3d20a26e8e2b', tokenDecimals: 18, url: null, isNativeToken: false, isSwap: false },
                    { currency: 'USDT', name: 'MOCK USDT', address: '0xfce7187b24fcdc9feffe428ec9977240c6f7006d', tokenDecimals: 6, url: null, isNativeToken: false, isSwap: false },
                ],
            };
            return [sepolia];
        },
        // 是否禁用按钮
        isDisabledButton() {
            // console.log(this.currentBlockchain);
            // console.log(this.currentToken);
            return this.currentBlockchain == null || this.currentToken == null;
        }
    },
    watch:{
        // 监听区块链更新清空已选中的代币
        currentBlockchain: {
            handler(n, o) {
                if (n != o) {
                    this.currentToken = null;
                    this.balance = 0;
                }
            },
            deep: true
        },
        // 监听代币更新查询余额
        currentToken: {
            handler(n, o) {
                if (n) {
                    this.getBalance();
                }
            },
            deep: true
        }
    },
    methods: {
        // 查询代币余额
        async getBalance() {
            if(this.user?.wallet) {
                this.balance = 0;
                let originalBalance = 0;
                let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain?.blockchain?.RPCUrl));
                // 本地代币
                if(this.currentToken.isNativeToken) {
                    originalBalance = await web3.eth.getBalance(this.user.wallet);
                } else {
                    // ERC20代币
                    let contract = new web3.eth.Contract(this.currentBlockchain?.blockchain?.ERC20StandardAbi, this.currentToken.address);
                    originalBalance = await contract.methods.balanceOf(this.user.wallet).call();
                }
                this.balance = this.$web3Utils.fromWei(originalBalance, this.currentToken.tokenDecimals);
            }
        },
        // 确认铸币，调起钱包
        async mint() {
            if (this.currentBlockchain == null || this.currentToken == null) {
                return;
            }
            this.loading = true;
            // 创建 web3 对象
            let web3 = new Web3(new Web3.providers.HttpProvider(this.currentBlockchain?.blockchain?.RPCUrl));
            // 将函数编码
            let method  = null;
            // 将参数编码
            let param  = null;
            // 编码参数列表
            try {
                switch (this.currentBlockchain?.blockchain?.blockchain) {
                    case 'Sepolia':
                        let size = 100;
                        switch (this.currentToken.currency) {
                            case 'WBTC': size = 100; break;
                            case 'WETH': size = 2500; break;
                            case 'SOL': size = 50000; break;
                            case 'LINK': size = 330000; break;
                            case 'UNI': size = 625000; break;
                            case 'UN': size = 1250000000; break;
                            case 'USDT': size = 10000000; break;
                            case 'USDC': size = 10000000; break;
                            default:
                                this.$store.dispatch('snackbarMessageHandler', "Unsupported faucet");
                                this.loading = false;
                                return;
                        }
                        method = web3.eth.abi.encodeFunctionSignature('mint(uint256)');
                        param = web3.eth.abi.encodeParameters(['uint256'], [this.$web3Utils.toWei(size, this.currentToken.tokenDecimals)]).substring(2);
                        break;
                    case 'Mumbai':
                        switch (this.currentToken.currency) {
                            case 'WETH':
                                // 100个代币
                                method  = web3.eth.abi.encodeFunctionSignature('mint(address,uint256)');
                                param = web3.eth.abi.encodeParameters(['address', 'uint256'], [this.user.wallet, this.$web3Utils.toWei(100, this.currentToken.tokenDecimals)]).substring(2);
                                break;
                            case 'USDT':
                                // 100万个代币
                                method  = web3.eth.abi.encodeFunctionSignature('faucet(address)');
                                param = web3.eth.abi.encodeParameters(['address'], [this.user.wallet]).substring(2);
                                break;
                            default:
                                this.$store.dispatch('snackbarMessageHandler', "Unsupported faucet");
                                this.loading = false;
                                return;
                        }
                        break;
                    default:
                        this.$store.dispatch('snackbarMessageHandler', "Unsupported faucet");
                        this.loading = false;
                        return;
                }
                // 组装数据
                let data = method + param;
                // 调起钱包发送交易
                if(this.walletType){
                    this.$refs[this.walletType].sendTransaction(this.currentBlockchain?.blockchain?.blockchain, this.user.wallet, this.currentToken.address, data);
                } else {
                    // 错误通知
                    this.$store.dispatch('snackbarMessageHandler', "Invalid wallet type");
                    // 加载完成
                    this.loading = false;
                }
            } catch (error) {
                console.error(error);
                this.$store.dispatch('snackbarMessageHandler', error);
                // 加载完成
                this.loading = false;
            }
        },
        // 交易关闭：成功/失败
        async transactionClose(success, transactionHash) {
            this.transactionHash = transactionHash;
            // 交易成功
            if(success) {
                this.claimed = true;
            }
            // 加载完成
            this.loading = false;
        },
        // 关闭弹窗
        closeDialog() {
            // 如果已领取，需要刷新数据
            if(this.claimed) {
                // 取消领取
                this.claimed = false;
                // 刷新代币余额
                this.getBalance();
            } else {
                // 关闭弹窗
                this.dialog = false;
                this.currentBlockchain = null;
                this.currentToken = null;
            }
        }
    }
}
</script>
<style scoped>
:deep(.v-echarts-dialog){
    width: auto;
}
</style>