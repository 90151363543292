import axios from '@/config/api.request'

export default {
    // 判断用户是否有封装权限
    wrapPermission(id) {
        return axios.request({
            url: '/crypto/permission/wrap',
            method: 'get'
        })
    },
    // 封装加密货币
    wrap(data) {
        return axios.request({
            url: '/crypto/wrap',
            method: 'post',
            data: data,
        })
    },
    // 根据主键查询加密货币
    getCryptoById(id) {
        return axios.request({
            url: '/crypto/crypto',
            method: 'get',
            params: { id },
        })
    },
    // 分页查询加密货币列表
    getCryptosByPage(page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/cryptos/page',
            method: 'get',
            params: { page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency },
        })
    },
    // 判断用户是否有购买权限
    buyPermission(id) {
        return axios.request({
            url: '/crypto/permission/buy',
            method: 'get',
            params: { id }
        })
    },
    // 根据用户主键查询该发起人的更多加密货币
    getMoreCryptosFromOriginator(userId, excludedCryptoId) {
        return axios.request({
            url: '/crypto/cryptos/originator/more',
            method: 'get',
            params: { userId, excludedCryptoId }
        })
    },
    // 查询加密货币摘要信息
    getCryptoSummaries(blockchain) {
        return axios.request({
            url: '/crypto/summaries',
            method: 'get',
            params: { blockchain }
        })
    },
    // 查询加密货币用户仪表盘摘要信息
    getCryptoDashboardSummaries(blockchain) {
        return axios.request({
            url: '/crypto/summaries/dashboard',
            method: 'get',
            params: { blockchain }
        })
    },
    // 根据用户名查询拥有的加密货币封装资产摘要信息
    getOwnedSummaries(username, blockchain) {
        return axios.request({
            url: '/crypto/summaries/owned',
            method: 'get',
            params: { username, blockchain }
        })
    },
    // 根据用户名查询封装的加密货币封装资产摘要信息
    getWrappedSummaries(username, blockchain) {
        return axios.request({
            url: '/crypto/summaries/wrapped',
            method: 'get',
            params: { username, blockchain }
        })
    },
    // 根据用户名分页查询拥有的加密货币列表
    getOwnedCryptosByPage(username, page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/cryptos/page/owned',
            method: 'get',
            params: { username, page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency },
        })
    },
    // 根据用户名分页查询拥有的加密货币列表
    getWrappedCryptosByPage(username, page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency) {
        return axios.request({
            url: '/crypto/cryptos/page/wrapped',
            method: 'get',
            params: { username, page, size, sortBy, profitExpectancy, blockchain, wrappedCurrency, quoteCurrency },
        })
    },
    // 根据加密货币主键查询价格范围
    getPriceRange(id) {
        return axios.request({
            url: '/crypto/price/range',
            method: 'get',
            params: { id }
        })
    },
}