<template>
	<div class="layoutContent lexend-deca">
        <div class="Grid_root__iRfoa grid-style-11">
            <div class="page_progressBlur Grid_a__vY7M8">
                <div class="d-flex flex-column ga-2">
                    <h2 class="typography_h1 text-quaternary">
                        Fair By Design: Zero Fees, Zero Conflicts
                    </h2>
                    <div class="Spacer_root__uoSvA" style="--height:48px"></div>
                    <p  class="fs-17 text-tertiary">
                        We succeed only when you do. No hidden charges, no profiting from losses - just pure, fair trading.
                    </p>
                </div>
                <div class="hide-mobile">
                    <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                    <h3 class="fs-21-bold text-quaternary">Traditional Platforms</h3>
                    <ul class="fs-17 text-tertiary mt-6 pl-8">
                        <li>Charge fees on every trade</li>
                        <li>Profit from your losses</li>
                        <li>Hidden charges and forced liquidations</li>
                        <li>Make money whether you win or lose</li>
                    </ul>
                    <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                    <h3 class="fs-21-bold text-quaternary">The Untrading Way</h3>
                    <ul class="fs-17 text-tertiary mt-6 pl-8">
                        <li>Zero trading fees</li>
                        <li>Only share in your wins</li>
                        <li>Full transparency</li>
                        <li>Aligned through ERC-5173</li>
                    </ul>
               </div>
            </div>
            <div class="Grid_b___vQi_">
                <div class="Hero_heroImageContainer Bleed_root__EzNZN">
                    <div class="HeroIllustration_root__K98Vh">
                        <div class="HeroIllustration_perspective__JlJ9c" aria-hidden="true">
                            <div class="HeroIllustration_3d__JMg8B HeroIllustration_base__H6llR">
                                <div class="HeroIllustration_3d__JMg8B HeroIllustration_sidebar__kBXAb">
                                    <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert page_trackingHeroImage Image_root " style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/c9876f83-eca5-4c70-b39f-a7573f6a5300/public">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw show-mobile">
                <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                <h3 class="fs-21-bold text-quaternary">Traditional Platforms</h3>
                <ul class="fs-17 text-tertiary mt-6 pl-8">
                    <li>Charge fees on every trade</li>
                    <li>Profit from your losses</li>
                    <li>Hidden charges and forced liquidations</li>
                    <li>Make money whether you win or lose</li>
                </ul>
                <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                <h3 class="fs-21-bold text-quaternary">The Untrading Way</h3>
                <ul class="fs-17 text-tertiary mt-6 pl-8">
                    <li>Zero trading fees</li>
                    <li>Only share in your wins</li>
                    <li>Full transparency</li>
                    <li>Aligned through ERC-5173</li>
                </ul>
            </div>
        </div>
        <div style="background:var(--bento-border);" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU my-16"></div>
        <div class="Grid_root__iRfoa ga-10 grid-style-12">
            <div class="Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Zero trading fees</h4>
                    <span class="fs-14 text-tertiary">
                        No commissions, no deposit fees, no withdrawal fees.
                    </span>
                </div>
            </div>
            <div class="Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">No profit from losses</h4>
                    <span class="fs-14 text-tertiary">
                        We never benefit when our users lose money.
                    </span>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Share only in realized gains</h4>
                    <span class="fs-14 text-tertiary">
                        The community, including the platform, participate only after you've made a profit.
                    </span>
                </div>
            </div>
            <div class="Grid_d__WN1qv">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Success-based contribution</h4>
                    <span class="fs-14 text-tertiary">
                        The more you succeed, the more we grow together.
                    </span>
                </div>
            </div>
            <div class="Grid_e__Mtxl7">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Aligned incentives</h4>
                    <span class="fs-14 text-tertiary">
                        Your best interest is our best interest—it's in our code.
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-16">
            <a href="https://erc-5173.org/" target="_blank" type="button" class="button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                <span class="gjcrSl" style="padding: 0px;">See how this works</span><v-icon class="text-white">mdi mdi-chevron-right</v-icon>
            </a>
        </div>
        <p class="mt-16 fs-17 text-tertiary">
            When you win, we win. When you don't, we don't. Simple as that.
        </p>
	</div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>