<template>
    <div>
        <keep-alive>
            <v-card color="transparent" elevation="0">
                <v-menu v-model="menu" :close-on-content-click="false" location="bottom">
                    <template v-slot:activator="{ props }">
                        <v-text-field v-bind="props" class="body-p" v-model="text" placeholder="Search" density="compact" variant="underlined" color="primary" clear-icon="mdi mdi-close" @keyup.enter="onSearch">
                            <template v-slot:append-inner>
                                <div class="px-2 py-1" :class="menu ? 'bg-primary' : ''">
                                    <v-icon icon="mdi mdi-magnify" @click="onSearch" :color="menu ? 'white' : ''"/>
                                </div>
                            </template>
                        </v-text-field>
                    </template>
                    <v-card width="calc(100% + 0px)" class="pa-4" rounded="0">
                        <v-card-title class="d-flex pa-0">
                            <v-spacer></v-spacer>
                            <v-icon aria-label="close" icon="mdi:mdi-close" class="text-primaryGrey pl-1" @click="menu = false"></v-icon>
                        </v-card-title>
                        <div class="d-flex justify-center">
                            <v-card width="400" color="transparent" rounded="0" elevation="0" class="px-2">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <h2 class="body-text-btn text-primaryGrey">
                                            Filters
                                        </h2>
                                    </v-col>
                                    <v-col cols="12" class="mt-4">
                                        <!-- 区块链 -->
                                        <v-select v-model="searchParam.blockchain" label="Blockchain" :items="blockchains" item-title="blockchain" item-value="blockchain" variant="outlined" density="comfortable" clearable hide-details>
                                            <template v-slot:selection="{ item  }" >
                                                <img class="mr-3" :src="item.raw.icon" width="24" />
                                                <span>{{ item.title }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" class="mt-4">
                                        <div class="d-flex align-center justify-center">
                                            <v-select v-model="searchParam.wrappedCurrency" :items="cryptoProducts" item-title="currency" item-value="currency" hide-details placeholder="Select token" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                                <template v-slot:item="{ props, item }">
                                                    <v-list-item v-bind="props">
                                                        <template v-slot:title>
                                                            <span class="mr-2">{{ item.raw.currency }}</span>
                                                            <span class="mr-2">({{ item.raw.name }})</span>
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                            <div class="home-h3 text-primaryGrey mx-5">/</div>
                                            <v-select v-model="searchParam.quoteCurrency" :items="cryptoQuotes" item-title="currency" item-value="currency" hide-details placeholder="Select token" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                                <template v-slot:item="{ props, item }">
                                                    <v-list-item v-bind="props">
                                                        <template v-slot:title>
                                                            <span class="mr-2">{{ item.raw.currency }}</span>
                                                            <span class="mr-2">({{ item.raw.name }})</span>
                                                        </template>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </div>
                                    </v-col>
                                    <v-col cols="12 mt-6">
                                        <h2 class="body-text-btn text-primaryGrey">
                                            nFR Parameters
                                        </h2>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 mt-6">
                                        <h3 class="body-h4 text-primaryGrey">Total Reward
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    <!-- {{ $t('common.totalReward') }} -->
                                                    The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                                </v-card>
                                            </v-menu>
                                        </h3>
                                        <div class="mt-2">
                                            <v-range-slider v-model="searchParam.totalRewardRatios" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="5" max="50" density="compact" hide-details thumb-label>
                                                <template v-slot:thumb-label="{ modelValue }">
                                                    {{ modelValue }}%
                                                </template>
                                            </v-range-slider>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 mt-8">
                                        <h3 class="body-h4 text-primaryGrey">Future Rewards (FR)
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    <!-- {{ $t('common.futureRewards') }} -->
                                                    Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                </v-card>
                                            </v-menu>
                                        </h3>
                                        <div class="mt-2">
                                            <v-range-slider v-model="searchParam.futureRewardRatios" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="88" max="95" density="compact" hide-details thumb-label>
                                                <template v-slot:thumb-label="{ modelValue }">
                                                    {{ modelValue }}%
                                                </template>
                                            </v-range-slider>
                                        </div>
                                    </v-col>
                                    <v-col cols="12" class="pa-0 mt-8 mb-6">
                                        <h3 class="body-h4 text-primaryGrey"># of generations
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    <!-- {{ $t('common.ofGenerations') }} -->
                                                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                </v-card>
                                            </v-menu>
                                        </h3>
                                        <div class="mt-2">
                                            <v-range-slider v-model="searchParam.numberOfGenerations" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="10" max="30" density="compact" hide-details thumb-label></v-range-slider>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </div>
                    </v-card>
                </v-menu>
            </v-card>
        </keep-alive>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import Filter from '@/components/common/Filter';
export default {
    data() {
        return {
            menu: false,
            text: null,
            searchParam: {
                blockchain: null,
                wrappedCurrency: 'unBTC',
                quoteCurrency: 'USDT',
                totalRewardRatios: [10,40],
                futureRewardRatios: [90,95],
                numberOfGenerations: [10,30]
            },
            cryptoQuotes: [
                { currency: 'USDT', name: 'USDT' },
                { currency: 'USDC', name: 'USD Coin' }
            ],
        }
    },
    components: { Filter },
    created() {

    },
    mounted() {

    },
    computed: {
        ...mapGetters(['user', 'blockchains', 'cryptoProducts']),
        
    },
    watch: {

    },
    methods: {
        // 跳转到搜索页面
        onSearch() {
            // 缓存搜索参数
            this.$store.dispatch('searchHandler', this.searchParam);
            // 有文本则跳转到搜索 unNFTs
            if (this.text != null && this.text.length > 0) {
                this.$router.push('/unnfts/search?text=' + this.text);
                setTimeout(() => {
                    // 通知刷新数据
                    this.$bus.emit('emitRefreshNFTs');
                }, 10);
            } else {
                // 没有文本则跳转到搜索 unCryptos
                this.$router.push('/uncryptos/search/results');
                setTimeout(() => {
                    // 通知刷新数据
                    this.$bus.emit('emitRefreshCryptos');
                }, 10);
            }
            
        }
    }
}
</script>
<style scoped lang="scss">
.v-field__append-inner {
    background-color: #03DAC5 !important;
}
</style>