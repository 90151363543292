// Styles
import '@mdi/font/css/materialdesignicons.css'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure your project is 
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'


// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
          fa,
          mdi,
        }
      },
    theme: {
        defaultTheme: 'light',
        themes: {
            light: {
                dark: false,
                colors:{
                    primary:"#03DAC5",
                    black: "#FFFFFF",
                    white: "#FFFFFF",
                    primaryGrey:"#7b7b7b",
                    d7d:"#d7d7d7",
                    darkGrey:"#007957",
                    grey:"#888888",
                    lightGray:'#d9d9d9',
                    black01:'#000000',
                    paleCyan:'#EDFEFF',
                    c9c:'#c9c9c9',
                    e6e:'#e6e6e6',
                    paleYellow:'#FDFAEC',
                    paleGreen:'#f5ffed',
                    white01:'#FFFFFF',
                    c4c:'#c4c4c4',
                    f2f:'#f2f2f2',
                    eee:'#eeeeee',
                    d9d:"#d9d9d9",
                    grey01:"#888888",
                    background:"#FFFFFF",
                    surface:"#FFFFFF",
                    white02:"#FFFFFF",
                    grey02:"#FFFFFF",
                    grey03:"#e6e6e6",
                    grey04:"#616161",
                    pinkTone:"#ffcfcf",
                    blue:"#95c8ff",
                    hover:"#03DAC5",
                    button01:"#FFFFFF",
                    grey05:"#7b7b7b",
                    green01:"#03DAC5",
                    button02:"#ffffff",
                    black02:"#000000",
                    hover01:"#03DAC5",
                    btnwhite:"#03DAC5",
                    grey06:"#8e8e8e",
                    e5e:"#E5E5E5",
                    polygon:"#8247E5",
                    666666:"#666666",
                    buyf8: '#F8F8F8',
                    aaaaaa: '#AAAAAA',
                    cccccc: '#CCCCCC',
                    'white-666666': '#FFFFFF',
                    'f2f2f2-666666': '#f2f2f2',
                    white03: '#424242',
                    homeBg:'#f5f5f5',
                    homeHover:'#000000',
                    'grey-darken-4':'#EEEEEE',
                    'dark-green':'#92e9dc',
                    'dark-red':'#ffc9cd',
                    'soft-lime-green':'#009a77',
                    'olive-green':'#34a853',
                    'dark-grey':'#f5f5f5',
                    'light-green':'#dbf5f2',
                    'home-h2':'#000000B3',
                    'tertiary':'#6f6e77',
                    'secondary':'#3c4149',
                    'quaternary':'#282a30',
                    'button03':'#eeedef',
                    'tooltip':'#f4f2f4',
                    'quinary':'#e9e8ea',
                    'bg-secondary':'#f9f8f9',
                    'very-dark':'#ffffff',
                    'text-quaternary':'#1a1523'
                }
            },
            dark: {
                dark: false,
                colors:{
                    primary:"#03DAC5",
                    black: "#000000",
                    white: "#ffffff",
                    primaryGrey:"#9e9e9e",
                    d7d:"#7b7b7b",
                    darkGrey:"#007957",
                    grey:"#1c1c1c",
                    lightGray:'#262626',
                    black01:'#FFFFFF',
                    paleCyan:'#1c1c1c',
                    paleGreen:'#353535',
                    c9c:'#2e2e2e',
                    e6e:'#000000',
                    paleYellow:"#424242",
                    white01:'#121212',
                    c4c:'#3B3B3B',
                    f2f:'#212121',
                    eee:'#2e2e2e',
                    d9d:"#2e2e2e",
                    grey01:"#424242",
                    background:"#141414",
                    surface:"#555555",
                    white02:"#424242",
                    grey02:"#1e1e1e",
                    grey03:"#191919",
                    pinkTone:"#ffbbbb",
                    blue:"#004080",
                    grey04:"#9e9e9e",
                    hover:"#ffffff",
                    button01:"#03DAC5",
                    grey05:"#ffffff",
                    green01:"#03DAC5",
                    button02:"#ffffff",
                    black02:"#000000",
                    hover01:"#03DAC5",
                    btnwhite:"#ffffff",
                    grey06:"#8e8e8e",
                    e5e:"#2E2E2E",
                    polygon:"#8247E5",
                    666666:"#666666",
                    buyf8: '#F8F8F8',
                    aaaaaa: '#AAAAAA',
                    cccccc: '#CCCCCC',
                    'white-666666': '#666666',
                    'f2f2f2-666666': '#666666',
                    white03: '#8a8f98',
                    homeBg: '#141414',
                    homeHover:'#ffffff',
                    'grey-darken-4':'#424242',
                    'dark-green':'#009a77',
                    'dark-red':'#B71C1C',
                    'soft-lime-green':'#75fb4c',
                    'olive-green':'#34a853',
                    'dark-grey':'#212121',
                    'light-green':'#4ba587',
                    'home-h2':'#FFFFFFB3',
                    'tertiary':'#8a8f98',
                    'secondary':'#d0d6e0',
                    'quaternary':'#f7f8f8',
                    'button03':'#28282c',
                    'tooltip':'#232326',
                    'quinary':'#282828',
                    'bg-secondary':'#1c1c1f',
                    'very-dark':'#08090a',
                    'text-quaternary':'#62666d'
                }
            }
        },
    }
})