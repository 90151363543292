<template>
    <div>
        <v-app-bar fixed elevation="0" color="background">
            <template v-slot:prepend>
                <v-row no-gutters justify="space-between" align="center">
                    <div class="pl-10">
                        <router-link to="/" class="pointer">
                            <logo type="header" color="light" responsive="mobile"></logo>
                        </router-link>
                    </div>
                    <div class="ml-8">
                        <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                            <template v-slot:activator="{ props }" >
                                <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                    <router-link to="/uncryptos" class="del-underline">
                                        <span class="text-primaryGrey gradient-text-hover">unCryptos</span>
                                    </router-link>
                                    <v-icon :icon="props['aria-expanded'] == 'true' ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" size="24" class="ml-2 mb-1" />
                                </span>
                            </template>
                            <v-list class="text-primaryGrey" bg-color="black">
                                <v-list-item>
                                    <a href="/uncrypto/wrap" class="del-underline" :class="cryptoWrapPermission ? '' : 'pointer-none'">
                                        <v-list-item-title class="gradient-underline-hover">
                                            <v-icon icon="mdi:mdi-plus-thick"  :class="cryptoWrapPermission ? 'text-primaryGrey' : 'text-lightGray pointer-none'"></v-icon>
                                            <a class="ml-3 gradient-text-hover" :class="cryptoWrapPermission ? 'text-primaryGrey' : 'text-lightGray pointer-none'">Wrap Crypto</a>
                                        </v-list-item-title>
                                    </a>
                                </v-list-item>
                                <v-list-item v-for="(menu, index) in cryptoMenus" :key="index" :value="index">
                                    <router-link :to="menu.to" class="del-underline">
                                        <v-list-item-title class="gradient-underline-hover">
                                            <v-icon v-if="menu.iconType == 'icon'" :icon="menu.icon" class="primaryGrey"></v-icon>
                                            <v-icon v-if="menu.iconType == 'image'" class="primaryGrey">
                                                <v-img :src="menu.icon"></v-img>
                                            </v-icon>
                                            <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                        </v-list-item-title>
                                    </router-link>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                    <div class="ml-8">
                        <v-menu transition="slide-y-transition" offset="10" width="180" open-on-hover>
                            <template v-slot:activator="{ props }" >
                                <span v-bind="props" class="text-primaryGrey pointer gradient-underline-hover">
                                    <router-link to="/unnfts" class="del-underline">
                                        <span class="text-primaryGrey gradient-text-hover">unNFTs</span>
                                    </router-link>
                                    <v-icon :icon="props['aria-expanded'] == 'true' ? 'mdi mdi-chevron-up' : 'mdi mdi-chevron-down'" size="24" class="ml-2 mb-1" />
                                </span>
                            </template>
                            <v-list class="text-primaryGrey" bg-color="black">
                                <v-list-item v-for="(menu, index) in nftMenus" :key="index" :value="index">
                                    <router-link :to="menu.to" class="del-underline">
                                        <v-list-item-title class="gradient-underline-hover">
                                            <v-icon :icon="menu.icon" class="primaryGrey"></v-icon>
                                            <a class="ml-3 text-primaryGrey gradient-text-hover">{{ menu.title }}</a>
                                        </v-list-item-title>
                                    </router-link>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-row>
            </template>
            <template v-slot:append>
                <v-row no-gutters justify="space-between">
                    <span class="mr-8" v-if="token == null">
                        <router-link :to="'/connectwallet?redirectUrl=' + currentUrl">
                            <v-menu open-on-hover location="top">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" color="primary" icon="mdi mdi-wallet-outline" class="pointer" size="28"></v-icon>
                                </template>
                                <v-card class="pa-3 text-primaryGrey" rounded="0">Connect Wallet</v-card>
                            </v-menu>
                        </router-link>
                    </span>
                    <Installation></Installation>
                    <Notifications class="mr-8"></Notifications>
                    <Theme color="primary" class="mr-8"></Theme>
                    <Avatar class="mr-8"></Avatar>
                </v-row>
            </template>
            <v-app-bar-title class="ml-n2">
                <v-row justify="center">
                    <v-col cols="6" class="mt-3">
                        <Search></Search>
                    </v-col>
                </v-row>
            </v-app-bar-title>
        </v-app-bar>
        
        <!-- 固定 Discord -->
        <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="del-underline" aria-label="Discord">
            <v-btn position="fixed" location="bottom right" class="mr-6 mb-12" icon variant="text" size="large" style="z-index: 999;">
                <v-menu v-model="fixedDiscordMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                    <template v-slot:activator="{ props }" >
                        <div v-bind="props" >
                            <v-icon class="icon" color="secondary" size="32">icon-dis</v-icon>
                        </div>
                    </template>
                    <v-card class="pa-3 text-primaryGrey" rounded="0">
                        <h6 class="body-p font-weight-thin text-none">We are online! How may I help you today? </h6>
                    </v-card>
                </v-menu>
            </v-btn>
        </a>
        <!--固定举报作弊图标 -->
        <v-btn position="fixed" location="bottom right" class="mr-6" icon variant="text" size="large" style="z-index: 999;" @click="reportCheatingDialog = true">
            <v-menu v-model="fixedReportCheatingMenu" transition="slide-y-transition" :offset="10" location="top" open-on-hover contained>
                <template v-slot:activator="{ props }" >
                    <div v-bind="props" >
                        <v-icon class="icon" color="primaryGrey" size="32">icon-alert</v-icon>
                    </div>
                </template>
                <v-card class="pa-3 text-primaryGrey" rounded="0">
                    <h6 class="body-p font-weight-thin text-none">Report Cheating</h6>
                </v-card>
            </v-menu>
        </v-btn>
        <!-- 举报作弊弹窗 -->
        <ReportCheating :show="reportCheatingDialog" @watchReportCheatingDialog="watchReportCheatingDialog"></ReportCheating>
        <!-- 消息条 -->
        <Snackbar></Snackbar>
    </div>
</template>
<script>
import Logo from '@/components/common/Logo';
import Theme from '@/components/common/Theme';
import Notifications from '@/components/common/Notifications';
import Installation from '@/components/common/Installation';
import Avatar from '@/components/user/Avatar';
import ReportCheating from '@/components/common/ReportCheating';
import Snackbar from '@/components/common/Snackbar';
import Search from '@/components/common/Search';
import { mapGetters } from "vuex";
import { mergeProps } from 'vue'
export default {
    data(){
        return {
            search: null,
            drawer: null,
            // 举报作弊弹窗
            reportCheatingDialog: false,
            currentUrl: window.location.href,
            // NFT 菜单
            nftMenus: [
                { title: 'Explore', icon: 'mdi:mdi-compass', to: '/market' },
                { title: 'Curated', icon: 'mdi:mdi-star', to: '/sets' },
                { title: 'Collections', icon: 'mdi:mdi-image-filter-black-white', to: '/collections' }
            ],
            // 固定 Discord 菜单
            fixedDiscordMenu: false,
            // 固定举报作弊菜单
            fixedReportCheatingMenu: false
        }
    },
    components: { Logo, Notifications, Installation, Avatar, ReportCheating, Theme, Snackbar, Search },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'token', 'cryptoWrapPermission', 'cryptoProducts']),
        // Crypto 菜单
        cryptoMenus() { 
            let menus = [
                { title: 'Markets', iconType: 'icon', icon: 'mdi:mdi-trending-up', to: '/uncryptos' },
            ];
            this.cryptoProducts.forEach(product => {
                let menu = {
                    title: product.currency,
                    iconType: 'image',
                    icon: product.icon,
                    to: `/uncryptos?product=${product.currency}`
                }
                menus.push(menu);
            });
            return menus;
        }
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        // 监听举报作弊弹窗
        watchReportCheatingDialog(show) {
            this.reportCheatingDialog = show;
        },
        mergeProps
    }
}
</script>
<style scoped>
</style>