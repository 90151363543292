const devConfig = require('../../config/dev.json')

// let data = getParamConfig('dev');						// 开发环境
// let data = getParamConfig('devUseTest');				// 开发环境
// let data = getParamConfig('devnet');					// 测试环境
// let data = getParamConfig('testnet');				// 测试环境
let data = getParamConfig('prod');					// 生产环境
 
function getParamConfig(env){
	let data = {
        title: '',
        env: '',
		baseUrl: '',
		domain: ''
	};
	switch (env) {
		case 'dev':
            data.env = 'dev';
			data.baseUrl = 'http://' + devConfig.host + ':' +  devConfig.port + '/dev/api/untrading';
            data.domain = 'http://' + devConfig.host + ':' +  devConfig.port;
			break;
		case 'devUseTest':
            data.env = 'dev';
			data.baseUrl = 'https://api-devnet.untrading.org/api/untrading';
			data.domain = 'http://' + devConfig.host + ':' +  devConfig.port;
			break;
		case 'devnet':
            data.env = 'devnet';
			data.baseUrl = 'https://api-devnet.untrading.org/api/untrading';
			data.domain = 'https://devnet.untrading.org';
			break;
        case 'testnet':
            data.env = 'testnet';
			data.baseUrl = 'https://api-testnet.untrading.org/api/untrading';
			data.domain = 'https://testnet.untrading.org';
			break;
		case 'prod':
            data.env = 'prod';
			data.baseUrl = 'https://api.untrading.org/api/untrading';
			data.domain = 'https://untrading.org';
			break;
	}
	return data;
}

export default data