<template>
	<div class="lexend-deca">
        <div class="extra-glow-crypto-content"></div>
        <v-row no-gutters align="center" justify="center">
            <v-col cols="12" sm="12" md="9" lg="9" xl="9" xxl="5" class="px-0">
                <v-img contain class="cryptoImg" aspect-ratio="1" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/aad2e856-ebd1-4197-ccd4-5df177c4e300/public"></v-img>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3" xl="3" xxl="2" class="d-flex flex-column px-0 c_inside r-p-x-6">
                <h2 class="text-quaternary typography_h1">
                    Transform Your Crypto Trading Experience
                </h2>
                <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                <p class="text-tertiary fs-17">
                    Untrading's unCrypto platform revolutionizes how you interact with digital assets. Here's what you need to know:
                </p>
                <div class="Spacer_root__uoSvA" style="--height:64px"></div>
                    <div class="ga-2 d-flex flex-column">
                        <h3 class="fs-21-bold text-secondary">
                            Buy and Sell unCryptos
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17">
                            Trade wrapped versions of top ERC-20 tokens like BTC and ETH.
                        </p>
                    </div>
            
                   <div class="ga-2 d-flex flex-column mt-16">
                        <h3 class="fs-21-bold text-secondary">
                            Whitelisted Tokens
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17">
                            Currently, five carefully selected tokens are available for wrapping.
                        </p>
                   </div>
                    
                    <div class="ga-2 d-flex flex-column mt-16">
                        <h3 class="fs-21-bold text-secondary">
                            Originator Control
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="text-tertiary fs-17">
                            Token originators set reward structures and generation cycles.
                        </p>
                    </div>
                    
                    <div class="ga-2 d-flex flex-column mt-16">
                        <h3 class="fs-21-bold text-secondary">
                            Kingdom Governance
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="fs-17 text-tertiary">
                            In the future, Kingdom Leaders will decide which assets to whitelist in their realms. Along with several customizations and extensions.
                        </p>
                    </div>
                    
                    <div class="ga-2 d-flex flex-column mt-16">
                        <h3 class="fs-21-bold text-secondary">
                            Limitless Potential
                        </h3>
                        <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                        <p class="fs-17 text-tertiary">
                            From NFTs to stocks, bonds, and real-world assets - anything can be tokenized.
                        </p>
                    </div>
                
            </v-col>
        </v-row>
	</div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {

    },
    watch:{

    },
    methods: {
        
    }
}
</script>
<style scoped>
</style>