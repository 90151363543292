import config from '@/config/index.js'
import blockchain from '@/config/blockchain.js'

const state = () => ({
	// 自适应屏幕: pc/mobile
	responsive: null,
    // 当前用户所有的套装
    sets: [],
    // 当前用户所有的收藏夹
    collections: [],
    // 执照集合
    licenses: [
        { id: 0, name: 'CBE-CC0', description: 'CC0 1.0 Universal' },
        { id: 1, name: 'CBE-ECR', description: 'Exclusive Commercial Rights (No Creator Retention of Exploitation Rights)' },
        { id: 2, name: 'CBE-NECR', description: 'Non-Exclusive Commercial Rights (Creator Retains Exploitation Rights)' },
        { id: 3, name: 'CBE-NECR-HS', description: 'Non-Exclusive Commercial Rights (Creator Retains Exploitation Rights) & Termination for Hate Speech' },
        { id: 4, name: 'CBE-PR', description: 'Personal Use License' },
        { id: 5, name: 'CBE-PR-HS', description: 'Personal Use License with Termination for Hate Speech' },
    ],
    // 支付代币价格
    paymentTokenPrice: {
        ETH: 0,
        MATIC: 0
    },
    // 货币价格集合
    prices: [
        { currency: 'BTC', price: 0 },
        { currency: 'ETH', price: 0 },
        { currency: 'MATIC', price: 0 },
        { currency: 'BNB', price: 0 },
        { currency: 'SHIB', price: 0 },
        { currency: 'SOL', price: 0 },
        { currency: 'LINK', price: 0 },
        { currency: 'UNI', price: 0 },
        { currency: 'UN', price: 0 },
        { currency: 'USDC', price: 0 },
        { currency: 'USDT', price: 0 }
    ],
    // 全局切换区块链网络
    blockchain: null,
	// 区块链集合
	blockchains: [],
    // 提示信息
    snackbarMessage: null,
    // 搜索
    search: {
        blockchain: null,
        wrappedCurrency: null,
        quoteCurrency: null,
        totalRewardRatios: [10,40],
        futureRewardRatios: [90,95],
        numberOfGenerations: [10,30]
    },
    // 加密货币封装权限
    cryptoWrapPermission: true,
    // 加密货币产品
    cryptoProducts: [],
})

const getters = {
	responsive: state => state.responsive,
	sets: state => state.sets,
	collections: state => state.collections,
	licenses: state => state.licenses,
	paymentTokenPrice: state => state.paymentTokenPrice,
	prices: state => state.prices,
	blockchain: state => {
        if (state.blockchain) {
            return state.blockchain;
        }
        state.blockchain = localStorage.getItem('untrading-blockchain');
        return state.blockchain;
    },
	blockchains: state => {
		if(config.env == 'prod') {
			return blockchain.mainnet;
		} else {
			return blockchain.testnet;
		}
	},
    snackbarMessage: state => state.snackbarMessage,
    search: state => {
        let search = JSON.parse(localStorage.getItem('untrading-search'));
        if (search) {
            state.search = search;
        }
        // if (state.search) {
        //     return state.search;
        // }
        // state.search = (state.search != null) ? state.search : {};
		return state.search;
    },
    cryptoWrapPermission: state => state.cryptoWrapPermission,
    cryptoProducts: state => state.cryptoProducts,
}

const actions = {
	responsiveHandler(context, payload){
		context.commit('responsiveHandler', payload);
	},
    setsHandler(context, payload){
		context.commit('setsHandler', payload);
	},
    collectionsHandler(context, payload){
		context.commit('collectionsHandler', payload);
	},
    licensesHandler(context, payload){
		context.commit('licensesHandler', payload);
	},
    paymentTokenPriceHandler(context, payload){
		context.commit('paymentTokenPriceHandler', payload);
	},
    pricesHandler(context, payload){
		context.commit('pricesHandler', payload);
	},
    blockchainHandler(context, payload){
		context.commit('blockchainHandler', payload);
	},
    snackbarMessageHandler(context, payload){
		context.commit('snackbarMessageHandler', payload);
	},
    searchHandler(context, payload){
		context.commit('searchHandler', payload);
	},
    cryptoWrapPermissionHandler(context, payload){
		context.commit('cryptoWrapPermissionHandler', payload);
	},
    cryptoProductsHandler(context, payload){
		context.commit('cryptoProductsHandler', payload);
	},
}

const mutations = {
	responsiveHandler(state, responsive){
		state.responsive = responsive;
	},
    setsHandler(state, sets){
		state.sets = sets;
	},
    collectionsHandler(state, collections){
		state.collections = collections;
	},
    licensesHandler(state, licenses){
		state.licenses = licenses;
	},
    paymentTokenPriceHandler(state, paymentTokenPrice){
		state.paymentTokenPrice = paymentTokenPrice;
	},
    pricesHandler(state, prices){
		state.prices = prices;
	},
    blockchainHandler(state, blockchain){
        if(blockchain == null){
			localStorage.removeItem('untrading-blockchain');
		} else {
			localStorage.setItem('untrading-blockchain', blockchain);
		}
		state.blockchain = blockchain;
	},
    snackbarMessageHandler(state, snackbarMessage){
        console.log(snackbarMessage);
		state.snackbarMessage = snackbarMessage;
        // 延时清空文本
        setTimeout(() => {
            state.snackbarMessage = null;
        }, 5001);
	},
    searchHandler(state, search){
		localStorage.setItem('untrading-search', JSON.stringify(search));
		state.search = search;
	},
    cryptoWrapPermissionHandler(state, cryptoWrapPermission){
		state.cryptoWrapPermission = cryptoWrapPermission;
	},
    cryptoProductsHandler(state, cryptoProducts){
		state.cryptoProducts = cryptoProducts;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}