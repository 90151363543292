<template>
	<div class="mt-88">
        <v-container class="r-p-x-0">
            <v-row no-gutters>
                <v-col cols="12" sm="6" class="ml-6 r-m-l-0  r-p-l-n">
                    <router-link to="/">
                        <div class="logo r-m-l-n" v-show="this.darkTheme == 0">
                            <logo type="footer" color="light" responsive="pc"></logo>
                        </div>
                        <div class="logo r-m-l-n" v-show="this.darkTheme == 1" >
                            <logo type="footer" color="dark" responsive="pc"></logo>
                        </div>
                    </router-link>
                </v-col>
                <v-col cols="12" sm="6" class="ml-5 r-m-l-0 mt-8">
                    <div class="body-p-small text-primaryGrey">
                        <span class="gradient-underline-hover">
                            <a href="https://docs.untrading.org/risks" aria-label="Risks" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                Risks
                            </a>
                        </span>
                        <span class="mx-2"> | </span>
                        <span class="gradient-underline-hover">
                            <a href="https://docs.untrading.org/community-guidelines" aria-label="Community guidelines" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                Community guidelines
                            </a>
                        </span>
                        <span class="mx-2"> | </span>
                        <span class="gradient-underline-hover">
                            <a href="https://docs.untrading.org/terms-of-service" aria-label="Terms of service" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                Terms of service
                            </a>
                        </span>
                        <span class="mx-2"> | </span>
                        <span class="gradient-underline-hover">
                            <a href="https://docs.untrading.org/privacy-policy" aria-label="Privacy police" class="del-underline gradient-text-hover text-primaryGrey" target="_blank">
                                Privacy police
                            </a>
                        </span>
                    </div>
                    <div class="body-h5 mt-6 text-primaryGrey d-flex flex-wrap">
                        <span>
                            <a href="https://creativecommons.org/licenses/by/4.0/" aria-label="Licenses"  target="_blank" class="del-underline pointer">
                            <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8554187d-f5dc-41f7-eb9e-637396d9d100/public" cover width="80" alt="" /></a>
                        </span>
                        <span class="ml-3">untrading.org</span>
                    </div>
                    <div class="body-p-small mt-2 text-primaryGrey">Except where otherwise noted, content on this site is licensed under a Creative Commons Attribution 4.0 International license.</div>
                    <div class="mt-16 d-flex">
                        <div>
                            <a href="https://x.com/untradingOrg" target="_blank" class="del-underline">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/d63c1182-e0bd-44cd-d5ad-7334f9b6b100/public"  aria-label="X (Twitter)" role="img" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://t.me/untrading" target="_blank" class="black--text del-underline" aria-label="Telegram">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/88bd42d6-38b2-4775-ebdc-b5d28b927500/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://discord.gg/D5rZdhzVWh" target="_blank" class="black--text del-underline" aria-label="Discord">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ac803eba-9354-40bd-3de0-1c6ab1cc6d00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://github.com/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/48737c90-42b0-4069-a44a-e3a4616fdb00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://podcasters.spotify.com/pod/show/untrading" target="_blank" class="text-decoration-none" aria-label="GitHub">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0032faef-692f-45ff-f4bd-35d85887d300/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="px-2">
                            <a href="https://untrading.medium.com/" target="_blank" class="del-underline" aria-label="Medium">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f9073383-7543-4c8b-475f-b33fe3911100/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div>
                            <a href="https://www.linkedin.com/company/untrading/" target="_blank" class="black--text del-underline" aria-label="Linkedin">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b270d74-2b67-43b6-2a9a-9e9af19a6d00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        <div class="pl-2">
                            <a href="https://www.youtube.com/@untrading" target="_blank" class="black--text del-underline" aria-label="Linkedin">
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/83372301-c889-4b7b-440a-623d356abc00/public" width="24" height="24"></v-img>
                            </a>
                        </div>
                        </div>
                        <div class="mt-4 d-flex">
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/570b4fc3-a9a5-4171-a286-c39ea70eb300/public" cover width="120" v-show="this.darkTheme == 0" class="mr-9"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/b74fc7e2-76e6-4886-dab5-4a915d291900/public" cover width="120" v-show="this.darkTheme == 1" class="mr-9"></v-img>
                            </div>
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/ad711f74-e320-42e4-a346-1473c1566f00/public" cover width="120" v-show="this.darkTheme == 0"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/8e64e336-d5ef-4fc4-48a7-3fa7eb9f8c00/public" cover width="120" v-show="this.darkTheme == 1"></v-img>
                            </div>
                        </div>
                        <div class="mt-1 d-flex">
                            <div>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/56abb3aa-9120-40b3-efdf-34300a8e1700/public" cover width="120" v-show="this.darkTheme == 0" class="mr-9"></v-img>
                                <v-img src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/f2fd4477-500d-4a8e-a78c-a6b69610ab00/public" cover width="120" v-show="this.darkTheme == 1" class="mr-9"></v-img>
                            </div>
                        </div>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import Logo from '@/components/common/Logo';
import { mapGetters } from "vuex";
export default {
    data(){
        return {

        }
    },
    components: { Logo },
    created(){

    },
    mounted(){

    },
    computed: {
        ...mapGetters(['darkTheme', 'token','responsive'])
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>