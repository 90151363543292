import axios from '@/config/api.request'

export default {
    // 查询 UN 代币余额
    getBalance() {
        return axios.request({
            url: '/user/reward/kingdom/balance',
            method: 'get'
        })
    },
    // 分页查询用户奖励列表
    getRewardsByPage(page, size, sortBy) {
        return axios.request({
            url: '/user/reward/kingdom/rewards/page',
            method: 'get',
            params: {
                page: page,
                size: size,
                sortBy: sortBy
            }
        })
    },
    // 分页查询用户奖励排行榜列表
    getLeaderboardsByPage(page, size) {
        return axios.request({
            url: '/user/reward/kingdom/leaderboards',
            method: 'get',
            params: { page, size }
        })
    },
}