<template>
	<div class="h-100">
        <v-container class="mb-9">
            <v-card max-width="1560" elevation="0" rounded="0" color="transparent" class="pt-8 mx-auto text-primaryGrey">
                <v-row no-gutters>
                    <v-col cols="12">
                        <v-card elevation="0" rounded="0" color="transparent" class="px-2">
                            <v-row no-gutters class="pb-8">
                                <v-col cols="12">
                                    <h1 class="sub-page-h1 text-primaryGrey">{{ selectedCryptoWrappeds?.name }} ({{ selectedCryptoWrappeds?.currency }})</h1>
                                </v-col>
                            </v-row>
                            <v-row no-gutters >
                                <v-col cols="4">
                                    <h4 class="body-h4 text-primaryGrey">
                                        {{ $tools.formatNumber(total, 0) }} Results
                                    </h4>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
                <v-divider class="my-8 mx-2 r-m-x-0"></v-divider>
                <v-row no-gutters>
                    <v-col cols="12" lg="3">
                        <v-card elevation="0" rounded="0" color="transparent" class="px-2">
                            <!-- <div class="text-primaryGrey">
                                <v-row no-gutters>
                                    <v-col cols="auto">
                                        <div class="d-flex flex-wrap">
                                            <div>
                                                <v-icon size="48">icon-eth</v-icon>
                                            </div>
                                            <div class="ml-6">
                                                <div class="body-text-btn">
                                                    Ether
                                                </div>
                                                <div class="body-p">ETH</div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="float-right body-text-btn" justify="end">
                                        {{ $tools.formatNumber(0) }}
                                    </v-col>
                                </v-row>
                            </div> -->
                            <!-- 标的价格图表 -->
                            <UnderlyingPriceLineChart :currency="selectedCryptoWrappeds?.underlyingCurrency"></UnderlyingPriceLineChart>
                            <!-- 过滤条件 -->
                            <v-row no-gutters class="r-p-l-0">
                                <v-col cols="12">
                                    <h3 class="body-text-btn">
                                        Filters
                                    </h3>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <!-- 区块链 -->
                                    <v-select v-model="searchParam.blockchain" label="Blockchain" :items="blockchains" item-title="blockchain" item-value="blockchain" variant="outlined" density="comfortable" clearable hide-details>
                                        <template v-slot:selection="{ item  }" >
                                            <img class="mr-3" :src="item.raw.icon" width="24" />
                                            <span>{{ item.title }}</span>
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="mt-4">
                                    <div class="d-flex align-center justify-center">
                                        <v-select v-model="searchParam.wrappedCurrency" :items="cryptoProducts" item-title="currency" item-value="currency" hide-details placeholder="Select token" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                            <template v-slot:item="{ props, item }">
                                                <v-list-item v-bind="props">
                                                    <template v-slot:title>
                                                        <span class="mr-2">{{ item.raw.currency }}</span>
                                                        <span class="mr-2">({{ item.raw.name }})</span>
                                                    </template>
                                                </v-list-item>
                                              </template>
                                        </v-select>
                                        <div class="home-h3 text-primaryGrey mx-5">/</div>
                                        <v-select v-model="searchParam.quoteCurrency" :items="cryptoQuotes" item-title="currency" item-value="currency" hide-details placeholder="Select token" rounded="0" class="body-p-small font-weight-bold" density="comfortable" variant="outlined">
                                            <template v-slot:item="{ props, item }">
                                                <v-list-item v-bind="props">
                                                    <template v-slot:title>
                                                        <span class="mr-2">{{ item.raw.currency }}</span>
                                                        <span class="mr-2">({{ item.raw.name }})</span>
                                                    </template>
                                                </v-list-item>
                                              </template>
                                        </v-select>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="mt-6 r-p-l-0">
                                <v-col cols="12">
                                    <h3 class="body-text-btn">
                                        nFR Parameters
                                    </h3>
                                </v-col>
                                <v-col cols="12" class="pa-0 mt-6">
                                    <h3 class="body-h4 text-primaryGrey">Total Reward
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <!-- {{ $t('common.totalReward') }} -->
                                                The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unNFT.
                                            </v-card>
                                        </v-menu>
                                    </h3>
                                    <div class="mt-2">
                                        <v-range-slider v-model="searchParam.totalRewardRatios" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="5" max="50" density="compact" hide-details thumb-label>
                                            <template v-slot:thumb-label="{ modelValue }">
                                                {{ modelValue }}%
                                            </template>
                                        </v-range-slider>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pa-0 mt-8">
                                    <h3 class="body-h4 text-primaryGrey">Future Rewards (FR)
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <!-- {{ $t('common.futureRewards') }} -->
                                                Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                            </v-card>
                                        </v-menu>
                                    </h3>
                                    <div class="mt-2">
                                        <v-range-slider v-model="searchParam.futureRewardRatios" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="88" max="95" density="compact" hide-details thumb-label>
                                            <template v-slot:thumb-label="{ modelValue }">
                                                {{ modelValue }}%
                                            </template>
                                        </v-range-slider>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pa-0 mt-8 mb-6">
                                    <h3 class="body-h4 text-primaryGrey"># of generations
                                        <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                            <template v-slot:activator="{ props }" >
                                                <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                            </template>
                                            <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                <!-- {{ $t('common.ofGenerations') }} -->
                                                The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                            </v-card>
                                        </v-menu>
                                    </h3>
                                    <div class="mt-2">
                                        <v-range-slider v-model="searchParam.numberOfGenerations" color="primary" track-color="black01" track-size="1" thumb-size="16" step="1" min="10" max="30" density="compact" hide-details thumb-label></v-range-slider>
                                    </div>
                                </v-col>
                                <v-col cols="12" class="pa-0 mt-8 mb-6">
                                    <div class="gradient-left-red-purple-block">
                                        <v-btn rounded="0" elevation="2" :loading="loading" color="button01" class="text-none text-grey05 block body-p-small-b" height="52" aria-label="Search" @click="onSearch">Search</v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" lg="9">
                        <v-card elevation="0" rounded="0" width="100%" color="transparent" class="pl-10 r-m-l-0 r-m-t-16 r-p-x-1">
                            <!-- 过滤条件 -->
                            <v-row no-gutters class="r-m-l-0">
                                <v-col cols="12">
                                    <v-row no-gutters justify="end">
                                        <v-col cols="12">
                                            <div class="float-right">
                                                <v-menu>
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn  aria-label="mdi-menu-down" variant="text" rounded="0" color="primaryGrey" class="text-none body-p ml-5" height="100%" v-bind="props">
                                                            {{ sortCondition }}
                                                            <v-icon aria-label="mdi-menu-down" end icon="mdi:mdi-menu-down" />
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item v-for="(sortCondition, index) in sortConditions" :key="index" :value="index" @click="changeSortCondition(sortCondition)">
                                                            <v-list-item-title>{{ sortCondition }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-divider class="mb-8 r-m-x-0"></v-divider>
                            <!-- 数据表格 -->
                            <v-data-table-server width="100%" fluid :headers="headers" :items="results" :items-length="total" :page="page" :items-per-page="size" :loading="loading" item-value="name" 
                                class="body-p text-primaryGrey" :class="responsive != 'pc' ? 'overflow-x-scroll' : ''" color="transparent" density="compact" hover
                                @update:page="updatePage($event)" @update:itemsPerPage="updateSize($event)">
                                <template v-slot:headers>
                                    <tr class="body-h5">
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-50'">ID</td>
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Originator</td>
                                        <td class="px-0" :class="responsive == 'pc' ? '' : 'td-width-150'" width="150">Owner</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Total R
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The total Rewards available for distribution as a percentage of the total realized profit each time an owner sells this unCrypto.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">FR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Future Rewards (FRs) are paid to previous owners and buyers based on the remaining profits. Future Rewards (FRs) = Total Rewards (R) - Originators' Rewards (ORs).
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Gen
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The number of generations of owners who are eligible for the Future Rewards (FRs) distribution each time an owner has a realized profit.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">OR
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" color="primaryGrey" class="pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    Share of Rewards available for distribution among untrading.org and community treasurers.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-80'" width="80">Flow
                                            <v-menu transition="slide-y-transition" open-on-hover :offset="10" location="top">
                                                <template v-slot:activator="{ props }" >
                                                    <v-icon icon="mdi:mdi-alert-circle-outline" class="text-primaryGrey pointer gradient-text-hover" v-bind="props"></v-icon>
                                                </template>
                                                <v-card max-width="420" class="pa-3 text-primaryGrey body-p" rounded="0">
                                                    The Length of Flow is calculated as the sum of all prior owners along with the present holder of this token, starting from the time it was initially minted.
                                                </v-card>
                                            </v-menu>
                                        </td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-250'">Price</td>
                                        <td class="px-0 text-right" :class="responsive == 'pc' ? '' : 'td-width-250'">Size</td>
                                    </tr>
                                    <tr>
                                        <td colspan="10" class="px-0 text-center">
                                            <v-divider color="primaryGrey" :thickness="3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:item="{ index, item }">
                                    <v-hover>
                                        <template v-slot:default="{ isHovering, props }">
                                            <tr class="pointer" v-bind="props" :class="isHovering ? 'bg-primary text-white' : ''" @click="goToUnCrypto(item)">
                                                <td class="px-0">{{ item.tokenId }}</td>
                                                <td class="px-0">
                                                    <UserAvatar :avatar="item.user?.avatar" :username="item.user?.username" :name="item.user?.name" :text-color="isHovering ? 'text-white' : ''"  :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td>
                                                <td class="px-0">
                                                    <UserAvatar :avatar="item.owner?.avatar" :username="item.owner?.username" :name="item.owner?.name" :text-color="isHovering ? 'text-white' : ''" :underline-color="isHovering ? 'white-underline-hover' : ''" :size="24"></UserAvatar>
                                                </td>
                                                <td class="px-0 text-right">{{ item.totalRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ 100 - item.originatorRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ item.numberOfGenerations }}</td>
                                                <td class="px-0 text-right">{{ item.originatorRewardRatio }}%</td>
                                                <td class="px-0 text-right">{{ item.maxOwnerNo }}</td>
                                                <td class="px-0 text-right">{{ (item.listedSize > 0 ? $tools.formatNumber(item.limitPrice, item.cryptoQuote?.sizeDecimals) : '--') }} {{ item.quoteCurrency }}</td>
                                                <td class="px-0 text-right">{{ item.listedSize > 0  ? $tools.formatNumber(item.listedSize, item.cryptoWrapped?.sizeDecimals) : $tools.formatNumber(item.currentSize, item.cryptoWrapped?.sizeDecimals) }} {{ item.wrappedCurrency }}</td>
                                            </tr>
                                        </template>
                                    </v-hover>
                                </template>
                                <template v-slot:loading>
                                    <!-- <tr>
                                        <td colspan="10">
                                            <div class="text-center">
                                                <v-progress-circular color="primary" class="my-7" :size="30" :width="3" indeterminate v-if="loading"></v-progress-circular>
                                            </div>
                                        </td>
                                    </tr> -->
                                    <v-skeleton-loader :type="`table-row@${size}`" color="transparent"></v-skeleton-loader>
                                </template>
                                <template v-slot:no-data>
                                    <NoDataAvailable></NoDataAvailable>
                                </template>
                                <template v-slot:tfoot>
                                    <tr>
                                        <td colspan="10" class="px-0">
                                            <v-divider thickness="3" color="primaryGrey" class="my-3"></v-divider>
                                        </td>
                                    </tr>
                                </template>
                                <template v-slot:bottom>
                                    <v-data-table-footer key="title" :items-per-page-options="perPageOptions"></v-data-table-footer>
                                </template>
                            </v-data-table-server>
                        </v-card>
                    </v-col>
                </v-row>
            </v-card>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnderlyingPriceLineChart from '@/components/charts/UnderlyingPriceLineChart.vue'
import SearchAPI from '@/api/search.js';
export default {
    data(){
        return {
            headers: [{},{},{},{},{},{},{},{},{},{}],
            // 加载中
            loading: false,
            // 查询文本
            text: this.$route.query.text,
            // 搜索条件
            searchParam: {
                blockchain: null,
                wrappedCurrency: 'unBTC',
                quoteCurrency: 'USDT',
                totalRewardRatios: [10,40],
                futureRewardRatios: [90,95],
                numberOfGenerations: [10,30]
            },
            // 加密货币报价资产选择框
            cryptoQuotes: [
                { currency: 'USDT', name: 'USDT' },
                { currency: 'USDC', name: 'USD Coin' }
            ],
            // 排序条件
            sortConditions: ['Newest', 'Oldest', 'Highest Price', 'Lowest Price'],
            // 默认排序条件
            sortCondition: 'Lowest Price',
            // 每页数量配置
            perPageOptions: [
                { title: '10', value: 10 },
                { title: '20', value: 20 },
                { title: '50', value: 50 },
                { title: '100', value: 100 }
            ],
            results: [],
            total: 0,
            page: 1,
            size: 10,
        }
    },
    components: { UnderlyingPriceLineChart },
    created(){
        // 监听刷新套装数据
        this.$bus.on('emitRefreshCryptos', data => {
            this.searchParam = JSON.parse(JSON.stringify(this.$store.getters.search));
            this.refresh();
        })
    },
    mounted(){
        this.searchParam = JSON.parse(JSON.stringify(this.$store.getters.search));
        this.refresh();
    },
    computed: {
        ...mapGetters(['responsive', 'user', 'blockchains', 'search', 'cryptoProducts']),
        // 选中的加密货币封装资产
        selectedCryptoWrappeds() {
            return this.cryptoProducts.filter(c => c.currency == this.searchParam.wrappedCurrency)[0];
        }
    },
    watch:{
        search: {
            handler(newVal, oldVal) {
                this.searchParam = newVal;
            },
            deep: true
        },
        'searchParam.blockchain'(newVal, oldVal){
            // 刷新
            this.refresh();
        },
        'searchParam.wrappedCurrency'(newVal, oldVal){
            // 刷新
            this.refresh();
        },
        'searchParam.quoteCurrency'(newVal, oldVal){
            // 刷新
            this.refresh();
        }
    },
    methods: {
        // 搜索
        async onSearch() {
            this.loading = true;
            this.results = [];
            let res = await SearchAPI.searchCryptos(
                this.page, this.size, this.sortCondition,
                this.text, this.searchParam.blockchain, this.searchParam.wrappedCurrency, this.searchParam.quoteCurrency, 
                this.searchParam.totalRewardRatios.join(','), 
                this.searchParam.futureRewardRatios.join(','), 
                this.searchParam.numberOfGenerations.join(',') 
            );
            let data = res.data;
            if (data.success) {
                this.total = data.data.total;
                this.results = data.data.records;
            }
            this.loading = false;
        },
        // 改变排序条件
        changeSortCondition(sortCondition) {
            // 改变条件
            this.sortCondition = sortCondition;
            // 刷新
            this.refresh();
        },
        // 刷新
        refresh() {
            this.$store.dispatch('searchHandler', this.searchParam);
            // 更新页码
            this.page = 1;
            // 搜索
            this.onSearch();
        },
        // 更新页码
        updatePage(page) {
            // 更新参数
            this.page = page;
            // 搜索
            this.onSearch();
        },
        // 更新每页数量
        updateSize(size) {
            // 更新参数
            this.page = 1;
            this.size = size;
            // 搜索
            this.onSearch();
        },
        // 跳转到加密货币详情页
        goToUnCrypto(unCrypto) {
            this.$router.push(`/uncrypto/${unCrypto.wrappedCurrency.toLowerCase()}/${unCrypto.id}`);
        },
        // 搜索
        async onSearchTest() {
            this.$store.dispatch('searchHandler', this.searchParam);
            let searchCryptoWrappeds = this.cryptoProducts;
            if (this.searchParam.wrappedCurrency) {
                searchCryptoWrappeds = this.cryptoProducts.filter(c => c.currency == this.searchParam.wrappedCurrency);
            }
            let searchCryptoQuotes = this.cryptoQuotes;
            if (this.searchParam.quoteCurrency) {
                searchCryptoQuotes = this.cryptoQuotes.filter(c => c.currency == this.searchParam.quoteCurrency);
            }
            this.results = [];
            // 创建所有匹配交易对
            searchCryptoWrappeds.forEach(CW => {
                searchCryptoQuotes.forEach(CQ => {
                    let result = {
                        wrappedCurrency: CW.currency,
                        quoteCurrency: CQ.currency,
                        total: 0,
                        page: 1,
                        size: 10,
                        results: []
                    };
                    this.results.push(result);
                });
            });
            this.results.forEach(async r => {
                let res = await SearchAPI.searchCryptos(this.searchParam.text, r.wrappedCurrency, r.quoteCurrency, 
                        this.searchParam.totalRewardRatios.join(','), 
                        this.searchParam.futureRewardRatios.join(','), 
                        this.searchParam.numberOfGenerations.join(',') );
                let data = res.data;
                if (data.success) {
                    r.total = data.data.total;
                    r.results = data.data.records;
                }
            });
        }
    }
}
</script>
<style scoped>

</style>