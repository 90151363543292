import axios from '@/config/api.request'

export default {
    // 回答问题
    answer(data) {
        return axios.request({
            url: '/challenge/user/answer/answer',
            method: 'post',
            data: data
        })
    },
}