<template>
	<div>
       <v-container class="content align-center r-p-x-0"  id="fadeIn" :style="`margin-bottom: ${footerHeight}px`">
            <v-container class="h-60vh bg-homeBg glow-effect r-p-x-0">
                <div class="kol-glow-header-right"></div>
                <div class="d-flex align-center justify-center h-50vh">
                    <div class="layoutContent">
                        <div class="Grid_root__iRfoa grid-style-17">
                            <div class="Grid_a__vY7M8">
                                <div class="d-flex flex-column ga-10">
                                    <h1 class="about-h1 marcellus-sc text-quaternary">
                                        Own Your Community's Success
                                    </h1>
                                    <p class="fs-21 lexend-deca text-tertiary">
                                        Bring your followers to Untrading and transform your influence into a sustainable, growing asset. It's time to truly own the value you create.
                                    </p>
                                    <div>
                                        <router-link to="/connectwallet?redirectUrl=/uncryptos" class="del-underline">
                                            <button class="btn button_root button_size-default button_variant lexend-deca">Connect Wallet</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
            <!-- Estimator -->
            <v-container class="pa-0 bg-black">
                <div class="layoutContentCal r-p-x-0">
                    <Estimator class="estimator glow-effect"></Estimator>
                </div>
            </v-container>
            <v-container class="pa-0 bg-homeBg r-p-x-0 kol-glow-effect">
                <v-row no-gutters class="py-300 r-p-y-150" justify="center" align="center">
                    <Payout></Payout>
                </v-row>
                <!-- Kingdom -->
                <v-row no-gutters justify="center" align="end" class="py-300 r-p-y-150 bg-black">
                    <div class="layoutContent">
                        <div class="d-flex flex-column mb-16">
                            <h2 class="typography_h1 text-quaternary lexend-deca">Build Your Kingdom on Untrading</h2>
                            <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                            <p class="fs-21-bold lexend-deca text-secondary">Become a King, or Queen, serve your realm, bridge the on-chain Silk Road.</p>
                            <div class="Spacer_root__uoSvA" style="--height:24px"></div>
                            <p class="fs-14 lexend-deca text-tertiary">Kingdoms on Untrading allow you to utilize our technologies to build your collaborative decentralized hedge pools for your community.</p> 
                        </div>
                        <Kingdom class="glow-effect"></Kingdom>
                    </div>
                </v-row>
                <div class="content-glow-bottom-left"></div>         
            </v-container>
       </v-container>
        <v-container class="footer bg-homeBg r-p-x-0" ref="footer">
            <v-row no-gutters justify="center" class="bg-homeBg">
                <v-col cols="12" sm="12" md="8" class="px-0">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-divider class="bg-homeBg"></v-divider>
            <v-row no-gutters justify="center" class="bg-homeBg" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" class="gradient-underline-hover h5 d-flex justify-end align-center my-2">
                    <Theme show-text size="22"></Theme>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import Estimator from './components/Estimator.vue';
import Footer from '@/views/home/v2/components/Footer.vue';
import Kingdom from './components/Kingdom.vue';
import Payout from './components/Payout.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            currentUrl: window.location.href,
            scroll: 0,
            footerHeight: 0,
        }
    },
    components: { Footer, Theme, Estimator, Kingdom, Payout },
    created(){

    },
    mounted() {
        this.calculateFooterHeight(); // 页面加载时计算 footer 高度
        window.addEventListener('resize', this.calculateFooterHeight); // 监听窗口大小变化
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateFooterHeight); // 移除事件监听
    },
    computed: {
        ...mapGetters(['token', 'responsive'])
    },
    watch:{
        $route: {
            handler(n, o) {
                this.currentUrl = window.location.href;
            },
            immediate: true
        },
    },
    methods: {
        calculateFooterHeight() {
            const footer = this.$refs.footer; // 访问组件的引用
            if (footer && footer.$el) { // 确保 footer 和 $el 都存在
                this.footerHeight = footer.$el.offsetHeight; // 获取 footer 高度
                console.log('Footer height:', this.footerHeight);
            } else {
                console.warn('Footer reference or $el is null');
            }
        },
    }
}
</script>
<style scoped>
.content {
    flex: 1;
    /* 自适应填充剩余空间 */
    position: relative;
    z-index: 1;
    /* 确保内容在 footer 之上 */
}

.footer {
    position: fixed;
    /* 使用固定定位 */
    bottom: 0;
    /* 使 footer 固定在底部 */
    z-index: 0;
    /* 确保 footer 在内容之下 */
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        /*初始状态 透明度为0*/
    }

    50% {
        opacity: 0.3;
        /*中间状态 透明度为0*/
    }

    100% {
        opacity: 1;
        /*结尾状态 透明度为1*/
    }
}

#fadeIn {
    -webkit-animation-name: fadeIn;
    /*动画名称*/
    -webkit-animation-duration: 0.5s;
    /*动画持续时间*/
    -webkit-animation-iteration-count: 1;
    /*动画次数*/
}
</style>