import axios from '@/config/api.request'

export default {
    // 查询全部加密货币盈利预期
    getCryptoProfitExpectancies() {
        return axios.request({
            url: '/crypto/profit/expectancy/list',
            method: 'get'
        })
    },
    // 查询加密货币盈利预期摘要信息
    getCryptoProfitExpectancySummaries(blockchain, product) {
        return axios.request({
            url: '/crypto/profit/expectancy/summaries',
            method: 'get',
            params: { blockchain, product }
        })
    },
    // 查询加密货币用户仪表盘盈利预期摘要信息
    getDashboardCryptoProfitExpectancySummaries(blockchain) {
        return axios.request({
            url: '/crypto/profit/expectancy/summaries/dashboard',
            method: 'get',
            params: { blockchain }
        })
    },
    // 根据用户名查询拥有的加密货币盈利预期摘要信息
    getOwnedCryptoProfitExpectancySummaries(username, blockchain) {
        return axios.request({
            url: '/crypto/profit/expectancy/summaries/owned',
            method: 'get',
            params: { username, blockchain }
        })
    },
    // 根据用户名查询封装的加密货币盈利预期摘要信息
    getWrappedCryptoProfitExpectancySummaries(username, blockchain) {
        return axios.request({
            url: '/crypto/profit/expectancy/summaries/wrapped',
            method: 'get',
            params: { username, blockchain }
        })
    },
}