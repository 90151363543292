<template>
	<div class="layoutContent lexend-deca">
        <div class="Grid_root__iRfoa grid-style-8 r-p-b-16">
            <div class="Grid_a__vY7M8">
                <h2 class="typography_h1">
                    Smart Exits: 
                    Trade with Profit Expectancy (PE)
                </h2>
            </div>
            <div class="Grid_b___vQi_ d-flex align-end text-tertiary r-m-t-16 fs-17">
                Know your optimal selling price before you buy. Our PE system learns from community success to help you untrade smarter.
            </div>
        </div>
        <div style="background:var(--bento-border);" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU mt-12"></div>
        <div class="page_bentoGrid__EhGwY Grid_root__iRfoa grid-style-2">
            <div class="page_bentoGridA__KQ2Vv Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Clear Goals</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Every token has a predefined profit target. No more emotional decisions or missed exits.</p>
                </div>
                <div class="Spacer_root__uoSvA" style="--height: 32px;"></div>
                <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                    <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                        <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                            <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert page_trackingHeroImage Image_root " style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/cc0dd599-65c5-413f-9dc7-6cac142b9c00/public">
                        </div>
                    </div>
                </div>
            </div>
            <div class="page_bentoGridB__uS8Oy Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Community Power</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">When everyone aims for the same target, the community grows stronger together.</p>
                </div>
                <div class="Spacer_root__uoSvA" style="--height: 32px;"></div>
                <div aria-hidden="true" class="ProjectOverview_root___sg3k">
                    <div class="GlassContainer_outer__e6vy_ utils_gradientBorder__9rE2_">
                        <div class="ProjectOverview_inner__hnVSN GlassContainer_inner__BdNcZ utils_gradientBorder__9rE2_">
                            <img alt="" data-nosnippet="true" data-loaded="true" loading="lazy" width="3200" height="1620" decoding="async" data-nimg="1" class="inert  page_trackingHeroImage Image_root " style="color:transparent" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/a74a2a54-3a36-4e39-775a-13484c2e1800/public">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="Grid_root__iRfoa grid-style-9">
            <div class="page_insightsCopy___vREc">
                <div class="Spacer_root__uoSvA" style="--height: 64px;"></div>
                <div class="ga-2 d-flex flex-column">
                    <h3 class="fs-21-bold text-quaternary">Enhanced Returns</h3>
                    <div class="Spacer_root__uoSvA" style="--height:4px"></div>
                    <p class="fs-17 text-tertiary">Future Rewards can multiply your actual returns far beyond your initial PE target.</p>
                </div>
            </div>
            <div class="page_insightsImageWrapper__82JS5 page_image-1">
                <div class="Spacer_root__uoSvA hide-mobile" style="--height: 300px;"></div>
                <div class="Spacer_root__uoSvA show-mobile" style="--height: 100px;"></div>
                <v-img  src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/08bcd0b3-b654-4a1e-b871-708b5b4bed00/public" cover class="Image_root__UkRqc page-image-1 rotate-image" style="color: transparent; position: relative;">
                </v-img>
            </div>
        </div>
        <div style="background:var(--bento-border);" role="none" aria-orientation="horizontal" data-orientation="horizontal" class="Separator_root__dpDqU my-12"></div>
        <div class="Grid_root__iRfoa ga-10 grid-style-10">
            <div class="Grid_a__vY7M8">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Quick & Steady</h4>
                    <span class="fs-14 text-tertiary">
                        Like a Hummingbird (5% PE), make frequent, small gains. Perfect for those who prefer consistent returns over risky moonshots.
                    </span>
                </div>
            </div>
            <div class="Grid_b___vQi_">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Growth & Balance</h4>
                    <span class="fs-14 text-tertiary">
                        Follow the Wolf (20% PE) strategy for balanced growth. Combined with Future Rewards, a 20% target can yield up to 100% in total returns.
                    </span>
                </div>
            </div>
            <div class="Grid_c__Tv5Qw">
                <div class="ga-2 d-flex flex-column">
                    <h4 class="fs-14 line-height-24 text-secondary">Patient Power</h4>
                    <span class="fs-14 text-tertiary">
                        Channel the Lion (25% PE) for larger gains. For those who understand that real wealth comes to those who wait and collaborate.
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-16">
            <a href="https://untrading.medium.com/mastering-untradings-profit-expectancy-76166f6abcfb" target="_blank" type="button" class="button_root button_variant-secondary button_size-small button_variant link_root" rel="noopener">
                <span class="gjcrSl" style="padding: 0px;">Explore PE Levels</span><v-icon class="text-white">mdi mdi-chevron-right</v-icon>
            </a>
        </div>
	</div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
    },
    watch:{

    },
    methods: {
    }
}
</script>
<style scoped>

</style>