<template>
	<div>
        <v-card max-width="600" class="r-m-r-1" rounded="0" elevation="0" color="transparent">
            <!-- 加密货币价格图表 -->
            <CryptoPriceLineChart v-if="unCrypto?.cryptoWrapped?.underlyingCurrency == 'UN'" :currency="unCrypto?.wrappedCurrency" :cryptoId="unCrypto?.id" :tokenId="unCrypto?.tokenId"></CryptoPriceLineChart>
            <!-- 标的价格图表 -->
            <UnderlyingPriceLineChart v-else :currency="unCrypto?.cryptoWrapped?.underlyingCurrency"></UnderlyingPriceLineChart>
            <!-- 信息 -->
            <v-row no-gutters>
                <v-col cols="12" class="px-0 d-flex align-center">
                    <h1 class="sub-page-h2 text-primaryGrey mr-3">
                        <span class="gradient-underline-hover">
                            <router-link :to="`/uncrypto/${unCrypto.wrappedCurrency?.toLowerCase()}/${unCrypto.id}`" class="text-primaryGrey gradient-text-hover">
                                {{ unCrypto.wrappedCurrency || '--' }} #{{ $tools.formatNumber(unCrypto.tokenId, 0) }}
                            </router-link>
                        </span>
                    </h1>
                    <v-icon v-if="unCrypto.blockchain == 'Ethereum' || unCrypto.blockchain == 'Goerli' || unCrypto.blockchain == 'Sepolia'" icon="mdi:mdi-ethereum" color="primaryGrey"></v-icon>
                    <v-icon v-if="unCrypto.blockchain == 'Arbitrum One'" color="primaryGrey">icon-arbitrum</v-icon>
                    <v-icon v-if="unCrypto.blockchain == 'Polygon' || unCrypto.blockchain == 'Mumbai'" color="polygon">icon-polygonscan</v-icon>
                    <h1 class="sub-page-h2 text-primaryGrey ml-3" v-show="unCrypto?.cryptoProfitExpectancy?.name">
                        {{ unCrypto?.cryptoProfitExpectancy?.name }} (+{{ $tools.formatNumber(unCrypto?.cryptoProfitExpectancy?.profitLowestPriceChangeRatio * 100, -1) }}%)
                    </h1>
                </v-col>
                <v-col cols="12" class="mt-7">
                    <v-row no-gutters class="text-primaryGrey">
                        <v-col cols="12" lg="6">
                            <h5 class="body-h5">Initial Supply: {{ $tools.formatNumber(unCrypto.size, unCrypto?.cryptoWrapped?.sizeDecimals) || '--' }}</h5>
                        </v-col>
                        <v-col cols="12" lg="6">
                            <h5 class="body-h5" :class="responsive == 'pc' ? 'pl-6' : ''">Minted on {{ $tools.formatEngLishDate(unCrypto.timestamp) || '' }}</h5>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7 mt-0"></v-divider>
                <v-col cols="12">
                    <v-row no-gutters align-content="center">
                        <v-col cols="6" class="text-primaryGrey">
                            <h5 class="body-h5 mb-2 text-primaryGrey">Originator</h5>
                            <div>
                                <UserAvatar :avatar="unCrypto?.user?.avatar" :username="unCrypto?.user?.username" :name="unCrypto?.user?.name"></UserAvatar>
                            </div>
                        </v-col>
                        <v-col cols="6" class="pl-6 text-primaryGrey">
                            <h5 class="body-h5 mb-2 text-primaryGrey">Owned by</h5>
                            <div>
                                <UserAvatar :avatar="unCrypto?.owner?.avatar" :username="unCrypto?.owner?.username" :name="unCrypto?.owner?.name"></UserAvatar>
                            </div>
                            <h6 class="mt-4 body-h5">Supply: {{ $tools.formatNumber(unCrypto.currentSize, unCrypto?.cryptoWrapped?.sizeDecimals) || '--' }}</h6>
                        </v-col>
                    </v-row>
                </v-col>
                <v-divider class="my-7"></v-divider>
                <h3 class="text-primaryGrey body-text-btn">Non-fungible Future Rewards (nFR) Parameters</h3>
                <v-divider class="my-7"></v-divider>
                <v-col cols="12" class="px-0">
                    <v-row no-gutters>
                        <v-col cols="6">
                            <TotalRewardValue :value="unCrypto.totalRewardRatio"></TotalRewardValue>
                        </v-col>
                        <v-col cols="6" class="pl-6">
                            <FutureRewardValue :value="100 - unCrypto.originatorRewardRatio"></FutureRewardValue>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-4">
                        <v-col cols="6">
                            <OriginatorRewardValue :value="unCrypto.originatorRewardRatio"></OriginatorRewardValue>
                        </v-col>
                        <v-col cols="6" class="pl-6">
                            <GenerationValue :value="unCrypto.numberOfGenerations"></GenerationValue>
                        </v-col>
                    </v-row>
                    <v-divider class="my-7" v-if="showTotal"></v-divider>
                    <v-row no-gutters v-if="showTotal">
                        <v-col cols="12" class="d-flex justify-space-between">
                            <div class="form-text text-primaryGrey">
                                Total nFR Paid: <span class="body-h2 text-primary ml-2"><b>{{ $tools.formatNumber(totalNFRPaid, unCrypto?.cryptoQuote?.sizeDecimals) }}</b></span>
                                <span class="ml-2">{{ unCrypto.quoteCurrency }}</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="my-7"></v-divider>
        </v-card>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import UnderlyingPriceLineChart from '@/components/charts/UnderlyingPriceLineChart.vue'
import CryptoPriceLineChart from '@/components/charts/CryptoPriceLineChart.vue'
import CryptoAPI from '@/api/crypto.js';
export default {
    data(){
        return {
            // 主键
            id: this.$route.params.id,
            // 加载中
            loading: false,
            // 封装后的加密货币
            unCrypto: {},
            // ETH 当前价格
            currentETHPrice: 0,
        }
    },
    props: {
        // 显示总值
        showTotal: {
            type: Boolean,
            default: true
        },
        // 总支付的奖励
        totalNFRPaid: {
            type: Number,
            default: 0
        }
    },
    components: { UnderlyingPriceLineChart, CryptoPriceLineChart },
    created(){

    },
    mounted(){
        
    },
    computed: {
        ...mapGetters(['responsive']),
    },
    watch:{
        $route(to, from) {
            // 要去的页面还是当前页面，则更新id，否则是去别的页面，不更新
            if(to.name == "UnCrypto") {
                this.id = this.$route.params.id;
            }
        },
        id: {
            handler(newVal, oldVal) {
                // 根据主键查询加密货币
                this.getCryptoById();
            },
            immediate: true
        }
    },
    methods: {
        // 根据主键查询加密货币
        async getCryptoById() {
            if (this.id == null) {
                return;
            }
            this.loading = true;
            let res = await CryptoAPI.getCryptoById(this.id);
            let data = res.data;
            if(data.success) {
                // 封装后的加密货币
                this.unCrypto = data.data;
            }
            this.loading = false;
        },
        // 接收当前价
        receiveCurrentPrice(currentPrice){
            this.currentETHPrice = currentPrice;
        },
    }
}
</script>
<style scoped>

</style>