import axios from '@/config/api.request'

export default {
    // 分页查询挑战问题及用户答案列表
    getQuestionsByPage(page, size) {
        return axios.request({
            url: '/challenge/question/questions/page',
            method: 'get',
            params: { page, size },
        })
    },
}