<template>
	<div v-for="(media, index) in medias" :key="index">
        <a :href="media.link" class="Link_root__cNtak" target="_blank" rel="noopener noreferrer">
            <div class="biIIjf Grid_root__iRfoa lexend-deca">
                <div class="Grid_a__vY7M8">
                    <span class="fs-14 text-quaternary">
                        {{ media.title }}
                    </span>
                </div>
                <div class="Grid_b___vQi_ text-tertiary">
                    <span class="fs-14">{{ media.subtitle }}</span>
                </div>
                <div class="Grid_c__Tv5Qw text-tertiary">
                    <span class="fs-14">
                    {{ media.source}}
                    </span>
                </div>
            </div>
        </a>
	</div>
</template>
<script>
export default {
    data(){
        return {
            medias:[
                {
                    title: 'Interview',
                    subtitle: 'Exploring ERC-5173 (nFR Future Rewards Contract) With Untrading CTO And Co-Founder Yale ReiSoleil Jr',
                    link: 'https://hackernoon.com/exploring-erc-5173-nfr-future-rewards-contract-with-untrading-cto-and-co-founder-yale-reisoleil-jr',
                    source: 'hackernoon.com'
                },
                {
                    title: 'DeFi News',
                    subtitle: 'Untrading: Bringing User-Centric Approach to Revolutionize Financial Landscape',
                    link: 'https://u.today/untrading-bringing-user-centric-approach-to-revolutionize-financial-landscape',
                    source: 'u.today'
                },
                {
                    title: 'Market Musing',
                    subtitle: 'The Untrading Ecosystem: A Paradigm Shift in Trading and Asset Management',
                    link: 'https://coinmarketcap.com/community/articles/66b52ab26023986fba122a58/',
                    source: 'coinmarketcap.com'
                },
                {
                    title: 'Feature Release',
                    subtitle: 'Provenance Value Amplification (PVA)',
                    link: 'Singapore-based Untrading Debuts Crypto Provenance Value Amplification (PVA) System on Testnet',
                    source: 'benzinga.com'
                },
                {
                    title: 'Crypto Trading',
                    subtitle: 'The Untrading Ecosystem: A Paradigm Shift in Trading and Asset Management',
                    link: 'https://cryptodaily.co.uk/2024/08/the-untrading-ecosystem-a-paradigm-shift-in-trading-and-asset-management',
                    source: 'cryptodaily.co.uk'
                },
                
            ],
        }
    },

    
    components: {  },
    created(){

    },
    mounted(){

    },
    computed: {
        
    },
    watch:{

    },
    methods: {

    }
}
</script>
<style scoped>

</style>