<template>
	<div>
        <v-container class="content align-center r-p-x-0"  id="fadeIn" :style="`margin-bottom: ${footerHeight}px`">
            <v-container class="h-v-60 bg-homeBg glow-effect r-p-x-0">
                <div class="kol-glow-header-right"></div>
                <div class="d-flex align-center justify-center h-50vh">
                    <div class="layoutContent">
                        <div class="Grid_root__iRfoa grid-style-17">
                            <div class="Grid_a__vY7M8">
                                <div class="d-flex flex-column ga-10">
                                    <h1 class="about-h1 marcellus-sc text-quaternary">
                                        Untrading is Bringing Magic to Financial Markets.
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
            <v-container class="pa-0 bg-black r-p-x-0 lexend-deca">
                <v-row no-gutters align="center" justify="center" class="py-200 r-p-y-0 r-p-y-16 bg-black">
                    <div class="layoutContent lexend-deca">
                        <div class="jmxpl Grid_root__iRfoa">
                            <div class="Grid_c__Tv5Qw">
                                <h2 class="about-h2">
                                    Transforming Losing Crypto Trading into a Cooperative, Community-Driven Experience
                                </h2>
                            </div>
                            <div class="Grid_d__WN1qv r-m-t-8">
                                <div class="d-flex flex-column ga-6 text-tertiary about-subtitle">
                                    <p>Untrading is revolutionizing the world of crypto and asset trading by shifting the paradigm from isolated, high-risk trading to a community-based, cooperative model. Traditional trading pits individuals against the volatility of the market, often leaving retail traders vulnerable to total losses. Untrading changes this by transforming the lone trader into part of a micro-community, called a Flow, where everyone benefits from each other's success.</p>
                                    <p class="gradient-underline-hover">Using the unique <a href="https://erc-5173.org" target="_blank" class="pointer text-tertiary about-underline-hover del-underline"><span class="gradient-text-hover">ERC-5173</span></a> standard, we've created a system where past owners continue to benefit from future price increases. It's a commission-free protocol for investing in both crypto and NFTs, designed to create a win-win scenario for all participants.</p>
                                    <p class="gradient-underline-hover">Untrading provides the native economic infrastructure for <a href="https://thenetworkstate.com/" target="_blank" class="pointer text-tertiary about-underline-hover del-underline"><span class="gradient-text-hover">Network States</span></a>, offering decentralized governance, shared economies, and scalable sovereignty. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="py-200 r-p-y-0 r-p-y-16 bg-homeBg">
                    <div class="layoutContent">
                        <div class="eyebrow text-tertiary lexend-deca fs-14">
                            Funding
                        </div>
                        <div class="itIYjJ Grid_root__iRfoa lexend-deca">
                            <div class="Grid_a__vY7M8">
                               <p class="fs-21 text-tertiary">
                                Self-Funding and Control: With over $2 million dollars invested with skin in the game, we are committed to reduce the conflicts in strategic direction or pressure for short-term gains at the expense of long-term value creation. 
                               </p>
                            </div>
                            <div class="Grid_d__WN1qv r-m-t-8">
                                 <img class="Image_root" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/bf698e65-42d0-4a36-5b93-44ca3d53e500/public" alt="">
                            </div>
                        </div>
                    </div>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="py-200 r-p-y-0 r-p-y-16 bg-black">
                    <div class="layoutContent">
                        <div class="eyebrow text-tertiary lexend-deca fs-14">
                            Team
                        </div>
                        <div class="team Grid_root__iRfoa lexend-deca">
                            <div class="Grid_a__vY7M8">
                               <p class="about-h3">
                                    United by Vision, Driven by Passion.
                               </p>
                            </div>
                            <div class="Grid_b___vQi_ fs-17 text-tertiary r-m-t-8 prose">
                                <p>
                                    Untrading's global team spans Singapore, China, North America, and Europe. We're united by:
                                </p>
                                <ul class="pl-8">
                                    <li>Relentless focus on innovation</li>
                                    <li>Swift execution of ideas</li>
                                    <li>Passion for reshaping finance</li>
                                </ul>
                                <p>We're makers at heart, committed to excellence in every detail. Though small, our diverse team is driving big changes in the financial market system.</p>
                            </div>
                            <div class="Grid_d__WN1qv r-m-t-8">
                                 <img class="Image_root" src="https://imagedelivery.net/P4z5C24T1A4q673nx_eYTw/0b68f785-5d06-4a3a-96b0-e994eab79800/public" alt="">
                            </div>
                        </div>
                        <div class="Spacer_root__uoSvA" style="--height:72px"></div>
                        <Team></Team>
                    </div>
                </v-row>
                <v-row no-gutters align="center" justify="center" class="py-200 r-p-y-0 r-p-y-16 bg-homeBg">
                    <div class="layoutContent">
                        <div class="eyebrow text-tertiary lexend-deca fs-14">
                            News
                        </div>
                        <News></News>
                    </div>
                </v-row>
            </v-container>
        </v-container>
        <v-container class="footer r-p-x-0" ref="footer">
            <!-- Footer -->
            <v-row no-gutters justify="center" class="bg-black">
                <v-col cols="12" sm="12" md="8">
                    <Footer></Footer>
                </v-col>
            </v-row>
            <v-row no-gutters justify="center" class="bg-black" v-if="responsive == 'pc'">
                <v-col cols="12" sm="12" md="8" class="gradient-underline-hover h5 d-flex justify-end align-center my-2">
                    <Theme show-text size="22"></Theme>
                </v-col>
            </v-row>
        </v-container>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import News from './components/News.vue';
import Team from './components/Team.vue';
import Footer from '@/views/home/v2/components/Footer.vue';
import Theme from '@/components/common/Theme';
export default {
    data(){
        return {
            scroll: 0,
            footerHeight: 0,
        }
    },
    components: { Footer, News, Theme, Team },
    created(){

    },
    mounted() {
        this.calculateFooterHeight(); // 页面加载时计算 footer 高度
        window.addEventListener('resize', this.calculateFooterHeight); // 监听窗口大小变化
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.calculateFooterHeight); // 移除事件监听
    },
    computed: {
        ...mapGetters(['token', 'responsive'])
    },
    watch:{

    },
    methods: {
        calculateFooterHeight() {
            const footer = this.$refs.footer; // 访问组件的引用
            if (footer && footer.$el) { // 确保 footer 和 $el 都存在
                this.footerHeight = footer.$el.offsetHeight; // 获取 footer 高度
                console.log('Footer height:', this.footerHeight);
            } else {
                console.warn('Footer reference or $el is null');
            }
        },
    }
}
</script>
<style scoped>
.content {
    flex: 1;
    /* 自适应填充剩余空间 */
    position: relative;
    z-index: 1;
    /* 确保内容在 footer 之上 */
}

.footer {
    position: fixed;
    /* 使用固定定位 */
    bottom: 0;
    /* 使 footer 固定在底部 */
    z-index: 0;
    /* 确保 footer 在内容之下 */
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
        /*初始状态 透明度为0*/
    }

    50% {
        opacity: 0.3;
        /*中间状态 透明度为0*/
    }

    100% {
        opacity: 1;
        /*结尾状态 透明度为1*/
    }
}

#fadeIn {
    -webkit-animation-name: fadeIn;
    /*动画名称*/
    -webkit-animation-duration: 0.5s;
    /*动画持续时间*/
    -webkit-animation-iteration-count: 1;
    /*动画次数*/
}
</style>