import { createApp } from 'vue'
import { createMetaManager} from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
// Vuetify: https://next.vuetifyjs.com/en/components/all/
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
// https://vue-i18n.intlify.dev/guide/installation.html
import { createI18n } from 'vue-i18n'
// JS初始化数据
import init from '@/store/init.js';
import config from '@/config/index.js'
import tools from '@/assets/js/tools.js'
import web3Utils from '@/assets/js/web3Utils.js'
import "bootstrap"
// ElementPlus: https://element-plus.org/zh-CN/component/button.html
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
// 自定义全局组件
import MetaMask from "@/components/wallet/MetaMask";
import WalletConnectV2 from "@/components/wallet/WalletConnectV2";
import OwnerAvatar from "@/components/user/OwnerAvatar";
import UserAvatar from "@/components/user/UserAvatar";
import SetAvatar from "@/components/set/SetAvatar";
import CollectionAvatar from "@/components/collection/CollectionAvatar";
import UnNftAvatar from "@/components/nft/UnNftAvatar";
import Step from "@/components/util/Step";
import NoDataAvailable from "@/components/common/NoDataAvailable";
import NoMoreData from "@/components/common/NoMoreData";
import CountTo from '@/components/util/count-to/CountTo.vue';
import Clipboard from '@/components/util/Clipboard.vue';
import ShareSocialMedia from '@/components/util/ShareSocialMedia.vue';
import Blockchain from '@/components/common/Blockchain.vue';
import TotalRewardValue from '@/components/config/crypto/TotalRewardValue.vue';
import FutureRewardValue from '@/components/config/crypto/FutureRewardValue.vue';
import OriginatorRewardValue from '@/components/config/crypto/OriginatorRewardValue.vue';
import GenerationValue from '@/components/config/crypto/GenerationValue.vue';
// 自定义全局事件指令
import TimeDirective from '@/directives/time.js';
import FunctionDirective from '@/directives/function.js';
import FunctionActionDirective from '@/directives/function-action.js';
import UsernameDirective from '@/directives/username.js';
import UnwrapSignatureStatusDirective from '@/directives/unwrap-signature-status.js';

//字体
import "@fontsource/lexend-deca"; // Defaults to weight 400
import "@fontsource/marcellus";
import "@fontsource/marcellus-sc";
import "@fontsource/montserrat"; // Defaults to weight 400



// 全局事件的发布和订阅与取消订阅
import mitt from 'mitt'
const bus = mitt()

// 自定义 CSS
import './assets/scss/_custom.scss'

// echarts
import * as echarts from 'echarts'
// PWA
import './registerServiceWorker'



loadFonts()

function getLanguage() {
    // 将选择的语言存在localStorage中
    let locale = localStorage.getItem("untrading-locale");
    if (locale) {
      return locale;
    } else {
      return "en-US";
    }
}
const i18n = createI18n({
    locale: getLanguage(),
    messages: {
        "en-US": require("@/assets/language/en-US.json"),
        "zh-CN": require("@/assets/language/zh-CN.json")
    }
})

// 创建一个应用实例，并安装插件
const app = createApp(App)
    .use(router)
    .use(store)
    .use(vuetify)
    .use(ElementPlus)
    .use(init)
    .use(i18n)
    .use(createMetaManager(false, {
      meta: { tag: 'meta', nameless: true }
     }))
    .component("CountTo", CountTo)
    .component("MetaMask", MetaMask)
    .component("WalletConnectV2", WalletConnectV2)
    .component("OwnerAvatar", OwnerAvatar)
    .component("UserAvatar", UserAvatar)
    .component("SetAvatar", SetAvatar)
    .component("CollectionAvatar", CollectionAvatar)
    .component("UnNftAvatar", UnNftAvatar)
    .component("Step", Step)
    .component("NoDataAvailable", NoDataAvailable)
    .component("NoMoreData", NoMoreData)
    .component("Clipboard", Clipboard)
    .component("ShareSocialMedia", ShareSocialMedia)
    .component("Blockchain", Blockchain)
    .component("TotalRewardValue", TotalRewardValue)
    .component("FutureRewardValue", FutureRewardValue)
    .component("OriginatorRewardValue", OriginatorRewardValue)
    .component("GenerationValue", GenerationValue)
;
// 全局属性
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$config = config;
app.config.globalProperties.$tools = tools;
app.config.globalProperties.$web3Utils = web3Utils;
app.config.globalProperties.$echarts = echarts;

// 全局指令
app.directive("time", TimeDirective)
app.directive("function", FunctionDirective)
app.directive("function-action", FunctionActionDirective)
app.directive("username", UsernameDirective)
app.directive("unwrap-signature-status", UnwrapSignatureStatusDirective)

// 将应用实例挂载在一个容器元素中
app.mount('#app');
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}